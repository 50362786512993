/* To integrate Material UI, we needed to costumise  tailwind's base css file */
/* Here is our version of the base file with at the top, what we changed from the tailwind, and at the bottom, the default file */
/* If you have a conflict between Material and tailwind, you need make your changes here */

/*****************************************************/
/*                  Overrided part                   */
/*****************************************************/

/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Remove default button styles.

Tricky css selector here, the default tailwind selector was applied on every button.
But we needed it to not be applied to Material Button -> :not(.MuiButton-root)
But because of this first "not" the selector of transparent was stronger than the bg-XXX-YYY of tailwind
so needed to remove this style if we added a class starting with "bg"
- class*=" bg-" is to handle the bg in a list of class while not handling the hover:bg-XXXX or focus:bg-XXXX
- class^="bg-" is to handle if the first element of the list of class is bg-XXX
'chakra-button' is to handle the buttons displayed in the react-spreadsheet-import library
*/

button:not([class^="bg-"]):not([class*=" bg-"]):not(.MuiButtonBase-root):not(.chakra-button),
[type="button"]:not([class^="bg-"]):not([class*=" bg-"]):not(.MuiButtonBase-root):not(.chakra-button),
[type="reset"]:not([class^="bg-"]):not([class*=" bg-"]):not(.MuiButtonBase-root):not(.chakra-button),
[type="submit"]:not([class^="bg-"]):not([class*=" bg-"]):not(.MuiButtonBase-root):not(.chakra-button) {
  -webkit-appearance: button; /* 1 */
  background-color: transparent; /* 2 */
}

/*****************************************************/
/*                 Base tailwind file                */
/*****************************************************/

/*
1. Prevent padding and border from affecting element width. (https://github.com/mozdevs/cssremedy/issues/4)
2. Allow adding a border to an element by just adding a border-width. (https://github.com/tailwindcss/tailwindcss/pull/116)
*/

*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  border-width: 0; /* 2 */
  border-style: solid; /* 2 */
  border-color: #E5EBEA; /* 2 */
}

::before,
::after {
  --tw-content: "";
}

/*
1. Use a consistent sensible line-height in all browsers.
2. Prevent adjustments of font size after orientation changes in iOS.
3. Use a more readable tab size.
4. Use the user's configured `sans` font-family by default.
*/

html {
  line-height: 1.5; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -moz-tab-size: 4; /* 3 */
  tab-size: 4; /* 3 */
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto; /* 4 */
}

/*
1. Remove the margin in all browsers.
2. Inherit line-height from `html` so users can set them as a class directly on the `html` element.
*/

body {
  margin: 0; /* 1 */
  line-height: inherit; /* 2 */
}

/*
1. Add the correct height in Firefox.
2. Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
3. Ensure horizontal rules are visible by default.
*/

hr {
  height: 0; /* 1 */
  color: inherit; /* 2 */
  border-top-width: 1px; /* 3 */
}

/*
Add the correct text decoration in Chrome, Edge, and Safari.
*/

abbr:where([title]) {
  text-decoration: underline dotted;
}

/*
Remove the default font size and weight for headings.
*/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

/*
Reset links to optimize for opt-in styling instead of opt-out.
*/

a {
  color: inherit;
  text-decoration: inherit;
}

/*
Add the correct font weight in Edge and Safari.
*/

b,
strong {
  font-weight: 500;
}

/*
1. Use the user's configured `mono` font family by default.
2. Correct the odd `em` font sizing in all browsers.
*/

code,
kbd,
samp,
pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/*
Add the correct font size in all browsers.
*/

small {
  font-size: 80%;
}

/*
Prevent `sub` and `sup` elements from affecting the line height in all browsers.
*/

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/*
1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
3. Remove gaps between table borders by default.
*/

table {
  text-indent: 0; /* 1 */
  border-color: inherit; /* 2 */
  border-collapse: collapse; /* 3 */
}

/*
1. Change the font styles in all browsers.
2. Remove the margin in Firefox and Safari.
3. Remove default padding in all browsers.
*/

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  font-weight: inherit; /* 1 */
  line-height: inherit; /* 1 */
  color: inherit; /* 1 */
  margin: 0; /* 2 */
  padding: 0; /* 3 */
}

/*
Remove the inheritance of text transform in Edge and Firefox.
*/

button,
select {
  text-transform: none;
}

/*
Use the modern Firefox focus style for all focusable elements.
*/

:-moz-focusring {
  outline: auto;
}

/*
Remove the additional `:invalid` styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
*/

:-moz-ui-invalid {
  box-shadow: none;
}

/*
Add the correct vertical alignment in Chrome and Firefox.
*/

progress {
  vertical-align: baseline;
}

/*
Correct the cursor style of increment and decrement buttons in Safari.
*/

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/*
1. Correct the odd appearance in Chrome and Safari.
2. Correct the outline style in Safari.
*/

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/*
Remove the inner padding in Chrome and Safari on macOS.
*/

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Change font properties to `inherit` in Safari.
*/

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/*
Add the correct display in Chrome and Safari.
*/

summary {
  display: list-item;
}

/*
Removes the default spacing and border for appropriate elements.
*/

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

/*
Prevent resizing textareas horizontally by default.
*/

textarea {
  resize: vertical;
}

/*
1. Reset the default placeholder opacity in Firefox. (https://github.com/tailwindlabs/tailwindcss/issues/3300)
2. Set the default placeholder color to the user's configured gray 400 color.
*/

input::placeholder,
textarea::placeholder {
  opacity: 1; /* 1 */
  color: #9CAFAC; /* 2 */
}

/*
Set the default cursor for buttons.
*/

button,
[role="button"] {
  cursor: pointer;
}

/*
Make sure disabled buttons don't get the pointer cursor.
*/
:disabled {
  cursor: default;
}

/*
1. Make replaced elements `display: block` by default. (https://github.com/mozdevs/cssremedy/issues/14)
2. Add `vertical-align: middle` to align replaced elements more sensibly by default. (https://github.com/jensimmons/cssremedy/issues/14#issuecomment-634934210)
   This can trigger a poorly considered lint error in some tools but is included by design.
*/

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block; /* 1 */
  vertical-align: middle; /* 2 */
}

/*
Constrain images and videos to the parent width and preserve their intrinsic aspect ratio. (https://github.com/mozdevs/cssremedy/issues/14)
*/

img,
video {
  max-width: 100%;
  height: auto;
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1.0;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
      -ms-transform: rotate(3deg) translate(0px, -4px);
          transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
          animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0%   { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes nprogress-spinner {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


*, ::before, ::after{
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-border-opacity: 1;
  border-color: rgb(229 235 234 / var(--tw-border-opacity));
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(57 122 184 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  --tw-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
/* Tailwind base add some css that conflict with Material UI components */
/* So we removed the file and added ours instead base on the tailwind file */
/* @tailwind base; */

/* Rest of tailwind CSS */
.container{
  width: 100%;
}
.\!container{
  width: 100% !important;
}
@media (min-width: 640px){
  .container{
    max-width: 640px;
  }
  .\!container{
    max-width: 640px !important;
  }
}
@media (min-width: 768px){
  .container{
    max-width: 768px;
  }
  .\!container{
    max-width: 768px !important;
  }
}
@media (min-width: 1024px){
  .container{
    max-width: 1024px;
  }
  .\!container{
    max-width: 1024px !important;
  }
}
@media (min-width: 1280px){
  .container{
    max-width: 1280px;
  }
  .\!container{
    max-width: 1280px !important;
  }
}
@media (min-width: 1536px){
  .container{
    max-width: 1536px;
  }
  .\!container{
    max-width: 1536px !important;
  }
}
@media (min-width: 1792px){
  .container{
    max-width: 1792px;
  }
  .\!container{
    max-width: 1792px !important;
  }
}
.prose{
  color: var(--tw-prose-body);
  max-width: 65ch;
}
.prose :where(p):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.prose :where([class~="lead"]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: var(--tw-prose-lead);
  font-size: 1.25em;
  line-height: 1.6;
  margin-top: 1.2em;
  margin-bottom: 1.2em;
}
.prose :where(a):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: var(--tw-prose-links);
  text-decoration: underline;
  font-weight: 500;
}
.prose :where(strong):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: var(--tw-prose-bold);
  font-weight: 600;
}
.prose :where(a strong):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: inherit;
}
.prose :where(blockquote strong):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: inherit;
}
.prose :where(thead th strong):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: inherit;
}
.prose :where(ol):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  list-style-type: decimal;
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-inline-start: 1.625em;
}
.prose :where(ol[type="A"]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  list-style-type: upper-alpha;
}
.prose :where(ol[type="a"]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  list-style-type: lower-alpha;
}
.prose :where(ol[type="A" s]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  list-style-type: upper-alpha;
}
.prose :where(ol[type="a" s]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  list-style-type: lower-alpha;
}
.prose :where(ol[type="I"]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  list-style-type: upper-roman;
}
.prose :where(ol[type="i"]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  list-style-type: lower-roman;
}
.prose :where(ol[type="I" s]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  list-style-type: upper-roman;
}
.prose :where(ol[type="i" s]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  list-style-type: lower-roman;
}
.prose :where(ol[type="1"]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  list-style-type: decimal;
}
.prose :where(ul):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  list-style-type: disc;
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-inline-start: 1.625em;
}
.prose :where(ol > li):not(:where([class~="not-prose"],[class~="not-prose"] *))::marker{
  font-weight: 400;
  color: var(--tw-prose-counters);
}
.prose :where(ul > li):not(:where([class~="not-prose"],[class~="not-prose"] *))::marker{
  color: var(--tw-prose-bullets);
}
.prose :where(dt):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: var(--tw-prose-headings);
  font-weight: 600;
  margin-top: 1.25em;
}
.prose :where(hr):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}
.prose :where(blockquote):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  font-weight: 500;
  font-style: italic;
  color: var(--tw-prose-quotes);
  border-inline-start-width: 0.25rem;
  border-inline-start-color: var(--tw-prose-quote-borders);
  quotes: "\201C""\201D""\2018""\2019";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-inline-start: 1em;
}
.prose :where(blockquote p:first-of-type):not(:where([class~="not-prose"],[class~="not-prose"] *))::before{
  content: open-quote;
}
.prose :where(blockquote p:last-of-type):not(:where([class~="not-prose"],[class~="not-prose"] *))::after{
  content: close-quote;
}
.prose :where(h1):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: var(--tw-prose-headings);
  font-weight: 800;
  font-size: 2.25em;
  margin-top: 0;
  margin-bottom: 0.8888889em;
  line-height: 1.1111111;
}
.prose :where(h1 strong):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  font-weight: 900;
  color: inherit;
}
.prose :where(h2):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: var(--tw-prose-headings);
  font-weight: 700;
  font-size: 1.5em;
  margin-top: 2em;
  margin-bottom: 1em;
  line-height: 1.3333333;
}
.prose :where(h2 strong):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  font-weight: 800;
  color: inherit;
}
.prose :where(h3):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: var(--tw-prose-headings);
  font-weight: 600;
  font-size: 1.25em;
  margin-top: 1.6em;
  margin-bottom: 0.6em;
  line-height: 1.6;
}
.prose :where(h3 strong):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  font-weight: 700;
  color: inherit;
}
.prose :where(h4):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: var(--tw-prose-headings);
  font-weight: 600;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  line-height: 1.5;
}
.prose :where(h4 strong):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  font-weight: 700;
  color: inherit;
}
.prose :where(img):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 2em;
  margin-bottom: 2em;
}
.prose :where(picture):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  display: block;
  margin-top: 2em;
  margin-bottom: 2em;
}
.prose :where(video):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 2em;
  margin-bottom: 2em;
}
.prose :where(kbd):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  font-weight: 500;
  font-family: inherit;
  color: var(--tw-prose-kbd);
  box-shadow: 0 0 0 1px rgb(var(--tw-prose-kbd-shadows) / 10%), 0 3px 0 rgb(var(--tw-prose-kbd-shadows) / 10%);
  font-size: 0.875em;
  border-radius: 0.3125rem;
  padding-top: 0.1875em;
  padding-inline-end: 0.375em;
  padding-bottom: 0.1875em;
  padding-inline-start: 0.375em;
}
.prose :where(code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: var(--tw-prose-code);
  font-weight: 600;
  font-size: 0.875em;
}
.prose :where(code):not(:where([class~="not-prose"],[class~="not-prose"] *))::before{
  content: "`";
}
.prose :where(code):not(:where([class~="not-prose"],[class~="not-prose"] *))::after{
  content: "`";
}
.prose :where(a code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: inherit;
}
.prose :where(h1 code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: inherit;
}
.prose :where(h2 code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: inherit;
  font-size: 0.875em;
}
.prose :where(h3 code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: inherit;
  font-size: 0.9em;
}
.prose :where(h4 code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: inherit;
}
.prose :where(blockquote code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: inherit;
}
.prose :where(thead th code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: inherit;
}
.prose :where(pre):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  overflow-x: auto;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.7142857;
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
  border-radius: 0.375rem;
  padding-top: 0.8571429em;
  padding-inline-end: 1.1428571em;
  padding-bottom: 0.8571429em;
  padding-inline-start: 1.1428571em;
}
.prose :where(pre code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
.prose :where(pre code):not(:where([class~="not-prose"],[class~="not-prose"] *))::before{
  content: none;
}
.prose :where(pre code):not(:where([class~="not-prose"],[class~="not-prose"] *))::after{
  content: none;
}
.prose :where(table):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  width: 100%;
  table-layout: auto;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: 0.875em;
  line-height: 1.7142857;
}
.prose :where(thead):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}
.prose :where(thead th):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: var(--tw-prose-headings);
  font-weight: 600;
  vertical-align: bottom;
  padding-inline-end: 0.5714286em;
  padding-bottom: 0.5714286em;
  padding-inline-start: 0.5714286em;
}
.prose :where(tbody tr):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}
.prose :where(tbody tr:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  border-bottom-width: 0;
}
.prose :where(tbody td):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  vertical-align: baseline;
}
.prose :where(tfoot):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  border-top-width: 1px;
  border-top-color: var(--tw-prose-th-borders);
}
.prose :where(tfoot td):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  vertical-align: top;
}
.prose :where(th, td):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  text-align: start;
}
.prose :where(figure > *):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0;
  margin-bottom: 0;
}
.prose :where(figcaption):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  color: var(--tw-prose-captions);
  font-size: 0.875em;
  line-height: 1.4285714;
  margin-top: 0.8571429em;
}
.prose{
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-kbd: #111827;
  --tw-prose-kbd-shadows: 17 24 39;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-kbd: #fff;
  --tw-prose-invert-kbd-shadows: 255 255 255;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: rgb(0 0 0 / 50%);
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}
.prose :where(picture > img):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0;
  margin-bottom: 0;
}
.prose :where(li):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.prose :where(ol > li):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  padding-inline-start: 0.375em;
}
.prose :where(ul > li):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  padding-inline-start: 0.375em;
}
.prose :where(.prose > ul > li p):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}
.prose :where(.prose > ul > li > p:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 1.25em;
}
.prose :where(.prose > ul > li > p:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-bottom: 1.25em;
}
.prose :where(.prose > ol > li > p:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 1.25em;
}
.prose :where(.prose > ol > li > p:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-bottom: 1.25em;
}
.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}
.prose :where(dl):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}
.prose :where(dd):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0.5em;
  padding-inline-start: 1.625em;
}
.prose :where(hr + *):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0;
}
.prose :where(h2 + *):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0;
}
.prose :where(h3 + *):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0;
}
.prose :where(h4 + *):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0;
}
.prose :where(thead th:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  padding-inline-start: 0;
}
.prose :where(thead th:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  padding-inline-end: 0;
}
.prose :where(tbody td, tfoot td):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  padding-top: 0.5714286em;
  padding-inline-end: 0.5714286em;
  padding-bottom: 0.5714286em;
  padding-inline-start: 0.5714286em;
}
.prose :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  padding-inline-start: 0;
}
.prose :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  padding-inline-end: 0;
}
.prose :where(figure):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 2em;
  margin-bottom: 2em;
}
.prose :where(.prose > :first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0;
}
.prose :where(.prose > :last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-bottom: 0;
}
.prose-sm{
  font-size: 0.875rem;
  line-height: 1.7142857;
}
.prose-sm :where(p):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 1.1428571em;
  margin-bottom: 1.1428571em;
}
.prose-sm :where([class~="lead"]):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  font-size: 1.2857143em;
  line-height: 1.5555556;
  margin-top: 0.8888889em;
  margin-bottom: 0.8888889em;
}
.prose-sm :where(blockquote):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 1.3333333em;
  margin-bottom: 1.3333333em;
  padding-inline-start: 1.1111111em;
}
.prose-sm :where(h1):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  font-size: 2.1428571em;
  margin-top: 0;
  margin-bottom: 0.8em;
  line-height: 1.2;
}
.prose-sm :where(h2):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  font-size: 1.4285714em;
  margin-top: 1.6em;
  margin-bottom: 0.8em;
  line-height: 1.4;
}
.prose-sm :where(h3):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  font-size: 1.2857143em;
  margin-top: 1.5555556em;
  margin-bottom: 0.4444444em;
  line-height: 1.5555556;
}
.prose-sm :where(h4):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 1.4285714em;
  margin-bottom: 0.5714286em;
  line-height: 1.4285714;
}
.prose-sm :where(img):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
}
.prose-sm :where(picture):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
}
.prose-sm :where(picture > img):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0;
  margin-bottom: 0;
}
.prose-sm :where(video):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
}
.prose-sm :where(kbd):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  font-size: 0.8571429em;
  border-radius: 0.3125rem;
  padding-top: 0.1428571em;
  padding-inline-end: 0.3571429em;
  padding-bottom: 0.1428571em;
  padding-inline-start: 0.3571429em;
}
.prose-sm :where(code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  font-size: 0.8571429em;
}
.prose-sm :where(h2 code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  font-size: 0.9em;
}
.prose-sm :where(h3 code):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  font-size: 0.8888889em;
}
.prose-sm :where(pre):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  font-size: 0.8571429em;
  line-height: 1.6666667;
  margin-top: 1.6666667em;
  margin-bottom: 1.6666667em;
  border-radius: 0.25rem;
  padding-top: 0.6666667em;
  padding-inline-end: 1em;
  padding-bottom: 0.6666667em;
  padding-inline-start: 1em;
}
.prose-sm :where(ol):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 1.1428571em;
  margin-bottom: 1.1428571em;
  padding-inline-start: 1.5714286em;
}
.prose-sm :where(ul):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 1.1428571em;
  margin-bottom: 1.1428571em;
  padding-inline-start: 1.5714286em;
}
.prose-sm :where(li):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0.2857143em;
  margin-bottom: 0.2857143em;
}
.prose-sm :where(ol > li):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  padding-inline-start: 0.4285714em;
}
.prose-sm :where(ul > li):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  padding-inline-start: 0.4285714em;
}
.prose-sm :where(.prose-sm > ul > li p):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0.5714286em;
  margin-bottom: 0.5714286em;
}
.prose-sm :where(.prose-sm > ul > li > p:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 1.1428571em;
}
.prose-sm :where(.prose-sm > ul > li > p:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-bottom: 1.1428571em;
}
.prose-sm :where(.prose-sm > ol > li > p:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 1.1428571em;
}
.prose-sm :where(.prose-sm > ol > li > p:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-bottom: 1.1428571em;
}
.prose-sm :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0.5714286em;
  margin-bottom: 0.5714286em;
}
.prose-sm :where(dl):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 1.1428571em;
  margin-bottom: 1.1428571em;
}
.prose-sm :where(dt):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 1.1428571em;
}
.prose-sm :where(dd):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0.2857143em;
  padding-inline-start: 1.5714286em;
}
.prose-sm :where(hr):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 2.8571429em;
  margin-bottom: 2.8571429em;
}
.prose-sm :where(hr + *):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0;
}
.prose-sm :where(h2 + *):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0;
}
.prose-sm :where(h3 + *):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0;
}
.prose-sm :where(h4 + *):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0;
}
.prose-sm :where(table):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  font-size: 0.8571429em;
  line-height: 1.5;
}
.prose-sm :where(thead th):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  padding-inline-end: 1em;
  padding-bottom: 0.6666667em;
  padding-inline-start: 1em;
}
.prose-sm :where(thead th:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  padding-inline-start: 0;
}
.prose-sm :where(thead th:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  padding-inline-end: 0;
}
.prose-sm :where(tbody td, tfoot td):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  padding-top: 0.6666667em;
  padding-inline-end: 1em;
  padding-bottom: 0.6666667em;
  padding-inline-start: 1em;
}
.prose-sm :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  padding-inline-start: 0;
}
.prose-sm :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  padding-inline-end: 0;
}
.prose-sm :where(figure):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
}
.prose-sm :where(figure > *):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0;
  margin-bottom: 0;
}
.prose-sm :where(figcaption):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  font-size: 0.8571429em;
  line-height: 1.3333333;
  margin-top: 0.6666667em;
}
.prose-sm :where(.prose-sm > :first-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-top: 0;
}
.prose-sm :where(.prose-sm > :last-child):not(:where([class~="not-prose"],[class~="not-prose"] *)){
  margin-bottom: 0;
}
.pattern-diagonal-lines{
  opacity: var(--pattern-opacity, 0.4);
  background-color: var(--pattern-bg-color, transparent);
  background: repeating-linear-gradient( 45deg, var(--pattern-color), var(--pattern-color) calc(var(--pattern-size, 40px) * 0.2), var(--pattern-bg-color, transparent) calc(var(--pattern-size, 40px) * 0.2), var(--pattern-bg-color) var(--pattern-size, 40px) );
}
.pointer-events-none{
  pointer-events: none;
}
.pointer-events-auto{
  pointer-events: auto;
}
.\!pointer-events-auto{
  pointer-events: auto !important;
}
.visible{
  visibility: visible;
}
.static{
  position: static;
}
.fixed{
  position: fixed;
}
.absolute{
  position: absolute;
}
.\!absolute{
  position: absolute !important;
}
.relative{
  position: relative;
}
.sticky{
  position: sticky;
}
.inset-0{
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.inset-y-0{
  top: 0px;
  bottom: 0px;
}
.inset-x-0{
  left: 0px;
  right: 0px;
}
.inset-y-4{
  top: 1rem;
  bottom: 1rem;
}
.inset-y-3{
  top: 0.75rem;
  bottom: 0.75rem;
}
.inset-x-16{
  left: 4rem;
  right: 4rem;
}
.inset-x-4{
  left: 1rem;
  right: 1rem;
}
.inset-y-1{
  top: 0.25rem;
  bottom: 0.25rem;
}
.-inset-x-2{
  left: -0.5rem;
  right: -0.5rem;
}
.bottom-\[20px\]{
  bottom: 20px;
}
.right-\[20px\]{
  right: 20px;
}
.top-16{
  top: 4rem;
}
.bottom-0{
  bottom: 0px;
}
.left-44{
  left: 11rem;
}
.top-0{
  top: 0px;
}
.right-4{
  right: 1rem;
}
.top-5{
  top: 1.25rem;
}
.left-1\/2{
  left: 50%;
}
.left-0{
  left: 0px;
}
.right-0{
  right: 0px;
}
.top-2\.5{
  top: 0.625rem;
}
.left-1{
  left: 0.25rem;
}
.top-2{
  top: 0.5rem;
}
.top-px{
  top: 1px;
}
.-bottom-2{
  bottom: -0.5rem;
}
.top-1\.5{
  top: 0.375rem;
}
.top-1{
  top: 0.25rem;
}
.-bottom-8{
  bottom: -2rem;
}
.left-8{
  left: 2rem;
}
.top-3{
  top: 0.75rem;
}
.-top-3{
  top: -0.75rem;
}
.-left-8{
  left: -2rem;
}
.bottom-2{
  bottom: 0.5rem;
}
.left-4{
  left: 1rem;
}
.-top-2{
  top: -0.5rem;
}
.bottom-4{
  bottom: 1rem;
}
.right-24{
  right: 6rem;
}
.right-3{
  right: 0.75rem;
}
.top-\[64px\]{
  top: 64px;
}
.-right-4{
  right: -1rem;
}
.-right-24{
  right: -6rem;
}
.left-\[408px\]{
  left: 408px;
}
.top-32{
  top: 8rem;
}
.top-4{
  top: 1rem;
}
.top-10{
  top: 2.5rem;
}
.top-1\/3{
  top: 33.333333%;
}
.left-\[40\%\]{
  left: 40%;
}
.top-\[20px\]{
  top: 20px;
}
.-bottom-1\.5{
  bottom: -0.375rem;
}
.-bottom-1{
  bottom: -0.25rem;
}
.-bottom-6{
  bottom: -1.5rem;
}
.right-10{
  right: 2.5rem;
}
.top-1\/2{
  top: 50%;
}
.left-1\/4{
  left: 25%;
}
.left-3\/4{
  left: 75%;
}
.left-1\/3{
  left: 33.333333%;
}
.left-2\/3{
  left: 66.666667%;
}
.bottom-2\.5{
  bottom: 0.625rem;
}
.-bottom-5{
  bottom: -1.25rem;
}
.bottom-3{
  bottom: 0.75rem;
}
.bottom-\[3\.75rem\]{
  bottom: 3.75rem;
}
.-right-3{
  right: -0.75rem;
}
.-left-3{
  left: -0.75rem;
}
.-bottom-4{
  bottom: -1rem;
}
.top-0\.5{
  top: 0.125rem;
}
.left-0\.5{
  left: 0.125rem;
}
.right-0\.5{
  right: 0.125rem;
}
.right-6{
  right: 1.5rem;
}
.right-1{
  right: 0.25rem;
}
.-top-4{
  top: -1rem;
}
.-left-10{
  left: -2.5rem;
}
.right-12{
  right: 3rem;
}
.top-\[3px\]{
  top: 3px;
}
.top-64{
  top: 16rem;
}
.-left-36{
  left: -9rem;
}
.bottom-1\/2{
  bottom: 50%;
}
.-bottom-12{
  bottom: -3rem;
}
.-top-24{
  top: -6rem;
}
.bottom-24{
  bottom: 6rem;
}
.right-8{
  right: 2rem;
}
.-left-4{
  left: -1rem;
}
.-top-12{
  top: -3rem;
}
.bottom-12{
  bottom: 3rem;
}
.left-10{
  left: 2.5rem;
}
.z-\[1500\]{
  z-index: 1500;
}
.z-10{
  z-index: 10;
}
.z-0{
  z-index: 0;
}
.z-20{
  z-index: 20;
}
.z-\[1301\]{
  z-index: 1301;
}
.z-50{
  z-index: 50;
}
.z-30{
  z-index: 30;
}
.z-\[1201\]{
  z-index: 1201;
}
.z-99{
  z-index: 99;
}
.z-\[9999\]{
  z-index: 9999;
}
.z-40{
  z-index: 40;
}
.col-span-2{
  grid-column: span 2 / span 2;
}
.col-span-full{
  grid-column: 1 / -1;
}
.col-span-1{
  grid-column: span 1 / span 1;
}
.col-span-3{
  grid-column: span 3 / span 3;
}
.col-start-1{
  grid-column-start: 1;
}
.-m-3{
  margin: -0.75rem;
}
.m-20{
  margin: 5rem;
}
.-m-4{
  margin: -1rem;
}
.-m-2{
  margin: -0.5rem;
}
.-m-6{
  margin: -1.5rem;
}
.m-0{
  margin: 0px;
}
.-m-5{
  margin: -1.25rem;
}
.m-\[-9px\]{
  margin: -9px;
}
.m-auto{
  margin: auto;
}
.m-2{
  margin: 0.5rem;
}
.m-1{
  margin: 0.25rem;
}
.m-4{
  margin: 1rem;
}
.m-0\.5{
  margin: 0.125rem;
}
.m-10{
  margin: 2.5rem;
}
.m-8{
  margin: 2rem;
}
.mx-auto{
  margin-left: auto;
  margin-right: auto;
}
.my-auto{
  margin-top: auto;
  margin-bottom: auto;
}
.my-20{
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.mx-0\.5{
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}
.mx-0{
  margin-left: 0px;
  margin-right: 0px;
}
.-mx-2{
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.mx-2{
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.my-6{
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.-mx-4{
  margin-left: -1rem;
  margin-right: -1rem;
}
.-mx-5{
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}
.-my-4{
  margin-top: -1rem;
  margin-bottom: -1rem;
}
.-mx-3{
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}
.\!-mx-5{
  margin-left: -1.25rem !important;
  margin-right: -1.25rem !important;
}
.-my-0\.5{
  margin-top: -0.125rem;
  margin-bottom: -0.125rem;
}
.-mx-px{
  margin-left: -1px;
  margin-right: -1px;
}
.-my-0{
  margin-top: -0px;
  margin-bottom: -0px;
}
.mx-4{
  margin-left: 1rem;
  margin-right: 1rem;
}
.my-4{
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.\!my-auto{
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.\!-mx-2{
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}
.my-2{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.-my-1{
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}
.mx-3{
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.mx-8{
  margin-left: 2rem;
  margin-right: 2rem;
}
.mx-16{
  margin-left: 4rem;
  margin-right: 4rem;
}
.\!mx-auto{
  margin-left: auto !important;
  margin-right: auto !important;
}
.my-10{
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.mx-1{
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.-mx-8{
  margin-left: -2rem;
  margin-right: -2rem;
}
.\!my-3{
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}
.\!-mx-6{
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}
.-mx-0\.5{
  margin-left: -0.125rem;
  margin-right: -0.125rem;
}
.-mx-0{
  margin-left: -0px;
  margin-right: -0px;
}
.my-0\.5{
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}
.my-0{
  margin-top: 0px;
  margin-bottom: 0px;
}
.mx-1\.5{
  margin-left: 0.375rem;
  margin-right: 0.375rem;
}
.-my-px{
  margin-top: -1px;
  margin-bottom: -1px;
}
.-mx-6{
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
.my-5{
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.my-1{
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.mx-12{
  margin-left: 3rem;
  margin-right: 3rem;
}
.\!-mx-4{
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}
.-my-2{
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}
.mx-px{
  margin-left: 1px;
  margin-right: 1px;
}
.my-\[1px\]{
  margin-top: 1px;
  margin-bottom: 1px;
}
.my-3{
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.\!-mx-3{
  margin-left: -0.75rem !important;
  margin-right: -0.75rem !important;
}
.\!my-6{
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.mt-2{
  margin-top: 0.5rem;
}
.\!ml-auto{
  margin-left: auto !important;
}
.mt-1{
  margin-top: 0.25rem;
}
.mt-16{
  margin-top: 4rem;
}
.mt-0{
  margin-top: 0px;
}
.mb-8{
  margin-bottom: 2rem;
}
.mr-auto{
  margin-right: auto;
}
.mt-5{
  margin-top: 1.25rem;
}
.ml-16{
  margin-left: 4rem;
}
.mr-7{
  margin-right: 1.75rem;
}
.ml-auto{
  margin-left: auto;
}
.mt-4{
  margin-top: 1rem;
}
.ml-2{
  margin-left: 0.5rem;
}
.mb-6{
  margin-bottom: 1.5rem;
}
.mr-4{
  margin-right: 1rem;
}
.mt-8{
  margin-top: 2rem;
}
.mt-12{
  margin-top: 3rem;
}
.mb-2{
  margin-bottom: 0.5rem;
}
.mr-16{
  margin-right: 4rem;
}
.mb-4{
  margin-bottom: 1rem;
}
.ml-1{
  margin-left: 0.25rem;
}
.-ml-1{
  margin-left: -0.25rem;
}
.-mb-0\.5{
  margin-bottom: -0.125rem;
}
.-mb-0{
  margin-bottom: -0px;
}
.-ml-0\.5{
  margin-left: -0.125rem;
}
.-ml-0{
  margin-left: -0px;
}
.-mt-16{
  margin-top: -4rem;
}
.-mb-2{
  margin-bottom: -0.5rem;
}
.mb-1{
  margin-bottom: 0.25rem;
}
.-mt-4{
  margin-top: -1rem;
}
.mr-2{
  margin-right: 0.5rem;
}
.\!-mb-4{
  margin-bottom: -1rem !important;
}
.mt-6{
  margin-top: 1.5rem;
}
.-mt-1{
  margin-top: -0.25rem;
}
.-ml-px{
  margin-left: -1px;
}
.-mb-8{
  margin-bottom: -2rem;
}
.-ml-6{
  margin-left: -1.5rem;
}
.-mb-3{
  margin-bottom: -0.75rem;
}
.mb-0\.5{
  margin-bottom: 0.125rem;
}
.mb-0{
  margin-bottom: 0px;
}
.mt-3{
  margin-top: 0.75rem;
}
.ml-5{
  margin-left: 1.25rem;
}
.-ml-3{
  margin-left: -0.75rem;
}
.-ml-2{
  margin-left: -0.5rem;
}
.\!mt-0{
  margin-top: 0px !important;
}
.-mt-0\.5{
  margin-top: -0.125rem;
}
.-mt-0{
  margin-top: -0px;
}
.\!-ml-9{
  margin-left: -2.25rem !important;
}
.ml-8{
  margin-left: 2rem;
}
.-mr-2{
  margin-right: -0.5rem;
}
.-ml-4{
  margin-left: -1rem;
}
.ml-px{
  margin-left: 1px;
}
.mr-8{
  margin-right: 2rem;
}
.\!-mr-8{
  margin-right: -2rem !important;
}
.\!mt-12{
  margin-top: 3rem !important;
}
.\!mt-4{
  margin-top: 1rem !important;
}
.\!-ml-2{
  margin-left: -0.5rem !important;
}
.ml-0{
  margin-left: 0px;
}
.\!mt-0\.5{
  margin-top: 0.125rem !important;
}
.\!mb-6{
  margin-bottom: 1.5rem !important;
}
.mr-1{
  margin-right: 0.25rem;
}
.\!mt-3{
  margin-top: 0.75rem !important;
}
.ml-4{
  margin-left: 1rem;
}
.mr-3{
  margin-right: 0.75rem;
}
.\!mt-2{
  margin-top: 0.5rem !important;
}
.\!mt-5{
  margin-top: 1.25rem !important;
}
.\!mb-auto{
  margin-bottom: auto !important;
}
.-ml-5{
  margin-left: -1.25rem;
}
.ml-6{
  margin-left: 1.5rem;
}
.mt-14{
  margin-top: 3.5rem;
}
.mb-20{
  margin-bottom: 5rem;
}
.mt-\[64px\]{
  margin-top: 64px;
}
.mr-6{
  margin-right: 1.5rem;
}
.mt-auto{
  margin-top: auto;
}
.-mb-4{
  margin-bottom: -1rem;
}
.ml-\[392px\]{
  margin-left: 392px;
}
.-mt-7{
  margin-top: -1.75rem;
}
.mb-auto{
  margin-bottom: auto;
}
.mt-32{
  margin-top: 8rem;
}
.mt-10{
  margin-top: 2.5rem;
}
.mb-28{
  margin-bottom: 7rem;
}
.mt-1\.5{
  margin-top: 0.375rem;
}
.ml-1\.5{
  margin-left: 0.375rem;
}
.mt-7{
  margin-top: 1.75rem;
}
.ml-64{
  margin-left: 16rem;
}
.\!-mt-4{
  margin-top: -1rem !important;
}
.\!mb-2{
  margin-bottom: 0.5rem !important;
}
.mr-px{
  margin-right: 1px;
}
.mt-2\.5{
  margin-top: 0.625rem;
}
.ml-14{
  margin-left: 3.5rem;
}
.\!mt-10{
  margin-top: 2.5rem !important;
}
.\!mb-8{
  margin-bottom: 2rem !important;
}
.ml-3{
  margin-left: 0.75rem;
}
.-mr-1{
  margin-right: -0.25rem;
}
.ml-2\.5{
  margin-left: 0.625rem;
}
.\!mt-px{
  margin-top: 1px !important;
}
.\!mt-auto{
  margin-top: auto !important;
}
.\!ml-1\.5{
  margin-left: 0.375rem !important;
}
.\!ml-1{
  margin-left: 0.25rem !important;
}
.-ml-1\.5{
  margin-left: -0.375rem;
}
.-mt-2{
  margin-top: -0.5rem;
}
.\!mb-3{
  margin-bottom: 0.75rem !important;
}
.\!ml-2{
  margin-left: 0.5rem !important;
}
.mb-3{
  margin-bottom: 0.75rem;
}
.-mb-1{
  margin-bottom: -0.25rem;
}
.mr-1\.5{
  margin-right: 0.375rem;
}
.-mr-6{
  margin-right: -1.5rem;
}
.-mr-14{
  margin-right: -3.5rem;
}
.\!mb-4{
  margin-bottom: 1rem !important;
}
.-mt-12{
  margin-top: -3rem;
}
.mb-5{
  margin-bottom: 1.25rem;
}
.mb-1\.5{
  margin-bottom: 0.375rem;
}
.mb-2\.5{
  margin-bottom: 0.625rem;
}
.\!mb-0{
  margin-bottom: 0px !important;
}
.\!-mb-5{
  margin-bottom: -1.25rem !important;
}
.\!mt-8{
  margin-top: 2rem !important;
}
.-mt-10{
  margin-top: -2.5rem;
}
.ml-\[2px\]{
  margin-left: 2px;
}
.mt-px{
  margin-top: 1px;
}
.mt-0\.5{
  margin-top: 0.125rem;
}
.\!-ml-3{
  margin-left: -0.75rem !important;
}
.\!mr-16{
  margin-right: 4rem !important;
}
.ml-12{
  margin-left: 3rem;
}
.ml-0\.5{
  margin-left: 0.125rem;
}
.\!mr-4{
  margin-right: 1rem !important;
}
.\!mb-5{
  margin-bottom: 1.25rem !important;
}
.\!-mt-0\.5{
  margin-top: -0.125rem !important;
}
.\!-mt-0{
  margin-top: -0px !important;
}
.\!mt-6{
  margin-top: 1.5rem !important;
}
.\!ml-0\.5{
  margin-left: 0.125rem !important;
}
.\!ml-0{
  margin-left: 0px !important;
}
.mt-24{
  margin-top: 6rem;
}
.-mb-10{
  margin-bottom: -2.5rem;
}
.box-border{
  box-sizing: border-box;
}
.block{
  display: block;
}
.inline-block{
  display: inline-block;
}
.inline{
  display: inline;
}
.\!inline{
  display: inline !important;
}
.flex{
  display: flex;
}
.inline-flex{
  display: inline-flex;
}
.\!inline-flex{
  display: inline-flex !important;
}
.table{
  display: table;
}
.grid{
  display: grid;
}
.contents{
  display: contents;
}
.list-item{
  display: list-item;
}
.hidden{
  display: none;
}
.h-\[48px\]{
  height: 48px;
}
.h-full{
  height: 100%;
}
.h-12{
  height: 3rem;
}
.h-16{
  height: 4rem;
}
.h-5{
  height: 1.25rem;
}
.h-4{
  height: 1rem;
}
.h-3{
  height: 0.75rem;
}
.h-10{
  height: 2.5rem;
}
.h-8{
  height: 2rem;
}
.h-6{
  height: 1.5rem;
}
.h-20{
  height: 5rem;
}
.h-2\.5{
  height: 0.625rem;
}
.h-2{
  height: 0.5rem;
}
.h-3\.5{
  height: 0.875rem;
}
.h-\[31px\]{
  height: 31px;
}
.h-\[64px\]{
  height: 64px;
}
.h-\[72px\]{
  height: 72px;
}
.h-32{
  height: 8rem;
}
.h-px{
  height: 1px;
}
.h-\[18px\]{
  height: 18px;
}
.h-9{
  height: 2.25rem;
}
.h-7{
  height: 1.75rem;
}
.h-\[22px\]{
  height: 22px;
}
.h-36{
  height: 9rem;
}
.h-24{
  height: 6rem;
}
.h-\[40\%\]{
  height: 40%;
}
.h-0{
  height: 0px;
}
.h-11{
  height: 2.75rem;
}
.h-\[20px\]{
  height: 20px;
}
.h-64{
  height: 16rem;
}
.h-4\/5{
  height: 80%;
}
.h-\[450px\]{
  height: 450px;
}
.h-96{
  height: 24rem;
}
.h-80{
  height: 20rem;
}
.h-\[1px\]{
  height: 1px;
}
.h-\[calc\(100vh-150px\)\]{
  height: calc(100vh - 150px);
}
.h-\[calc\(100vh-100px\)\]{
  height: calc(100vh - 100px);
}
.max-h-12{
  max-height: 3rem;
}
.max-h-6{
  max-height: 1.5rem;
}
.max-h-\[50vh\]{
  max-height: 50vh;
}
.max-h-screen{
  max-height: 100vh;
}
.min-h-\[3rem\]{
  min-height: 3rem;
}
.min-h-full{
  min-height: 100%;
}
.min-h-\[40px\]{
  min-height: 40px;
}
.min-h-\[32\.75px\]{
  min-height: 32.75px;
}
.min-h-\[92px\]{
  min-height: 92px;
}
.min-h-\[64px\]{
  min-height: 64px;
}
.min-h-\[4rem\]{
  min-height: 4rem;
}
.min-h-\[500px\]{
  min-height: 500px;
}
.min-h-\[600px\]{
  min-height: 600px;
}
.min-h-screen{
  min-height: 100vh;
}
.w-full{
  width: 100%;
}
.w-\[48px\]{
  width: 48px;
}
.w-60{
  width: 15rem;
}
.w-64{
  width: 16rem;
}
.w-40{
  width: 10rem;
}
.w-24{
  width: 6rem;
}
.w-\[560px\]{
  width: 560px;
}
.w-\[800px\]{
  width: 800px;
}
.w-\[640px\]{
  width: 640px;
}
.w-5{
  width: 1.25rem;
}
.w-4{
  width: 1rem;
}
.w-32{
  width: 8rem;
}
.w-12{
  width: 3rem;
}
.w-96{
  width: 24rem;
}
.w-48{
  width: 12rem;
}
.w-10{
  width: 2.5rem;
}
.w-8{
  width: 2rem;
}
.w-0\.5{
  width: 0.125rem;
}
.w-0{
  width: 0px;
}
.w-2\.5{
  width: 0.625rem;
}
.w-2{
  width: 0.5rem;
}
.w-6{
  width: 1.5rem;
}
.w-44{
  width: 11rem;
}
.w-36{
  width: 9rem;
}
.w-\[1024px\]{
  width: 1024px;
}
.w-\[410px\]{
  width: 410px;
}
.w-\[220px\]{
  width: 220px;
}
.w-3\/4{
  width: 75%;
}
.w-\[400px\]{
  width: 400px;
}
.w-\[34rem\]{
  width: 34rem;
}
.w-\[632px\]{
  width: 632px;
}
.w-\[520px\]{
  width: 520px;
}
.w-\[940px\]{
  width: 940px;
}
.w-2\/3{
  width: 66.666667%;
}
.w-16{
  width: 4rem;
}
.w-fit{
  width: fit-content;
}
.w-72{
  width: 18rem;
}
.w-80{
  width: 20rem;
}
.w-\[calc\(100\%-392px\)\]{
  width: calc(100% - 392px);
}
.w-\[calc\(100\%-16rem\)\]{
  width: calc(100% - 16rem);
}
.w-px{
  width: 1px;
}
.w-\[18px\]{
  width: 18px;
}
.w-\[480px\]{
  width: 480px;
}
.\!w-full{
  width: 100% !important;
}
.w-\[110\%\]{
  width: 110%;
}
.w-\[720px\]{
  width: 720px;
}
.w-1\/4{
  width: 25%;
}
.w-1\/3{
  width: 33.333333%;
}
.w-1\/2{
  width: 50%;
}
.w-3{
  width: 0.75rem;
}
.w-20{
  width: 5rem;
}
.w-\[600px\]{
  width: 600px;
}
.w-28{
  width: 7rem;
}
.w-\[460px\]{
  width: 460px;
}
.w-min{
  width: min-content;
}
.w-\[550px\]{
  width: 550px;
}
.w-\[264px\]{
  width: 264px;
}
.w-\[38px\]{
  width: 38px;
}
.w-56{
  width: 14rem;
}
.w-3\/5{
  width: 60%;
}
.w-4\/12{
  width: 33.333333%;
}
.w-\[500px\]{
  width: 500px;
}
.w-\[700px\]{
  width: 700px;
}
.w-auto{
  width: auto;
}
.w-\[416px\]{
  width: 416px;
}
.w-\[574px\]{
  width: 574px;
}
.w-\[calc\(100\%-28px\)\]{
  width: calc(100% - 28px);
}
.w-7{
  width: 1.75rem;
}
.w-\[250px\]{
  width: 250px;
}
.w-screen{
  width: 100vw;
}
.w-\[340px\]{
  width: 340px;
}
.w-1\/5{
  width: 20%;
}
.w-\[1px\]{
  width: 1px;
}
.w-\[610px\]{
  width: 610px;
}
.w-9{
  width: 2.25rem;
}
.w-52{
  width: 13rem;
}
.min-w-0{
  min-width: 0px;
}
.min-w-\[16rem\]{
  min-width: 16rem;
}
.min-w-\[904px\]{
  min-width: 904px;
}
.min-w-\[32px\]{
  min-width: 32px;
}
.min-w-\[424px\]{
  min-width: 424px;
}
.min-w-\[256px\]{
  min-width: 256px;
}
.min-w-\[35px\]{
  min-width: 35px;
}
.min-w-fit{
  min-width: fit-content;
}
.min-w-\[24px\]{
  min-width: 24px;
}
.min-w-\[350px\]{
  min-width: 350px;
}
.min-w-\[150px\]{
  min-width: 150px;
}
.min-w-\[280px\]{
  min-width: 280px;
}
.min-w-\[140px\]{
  min-width: 140px;
}
.min-w-\[200px\]{
  min-width: 200px;
}
.min-w-\[300px\]{
  min-width: 300px;
}
.\!min-w-min{
  min-width: min-content !important;
}
.min-w-\[600px\]{
  min-width: 600px;
}
.min-w-\[400px\]{
  min-width: 400px;
}
.min-w-\[24rem\]{
  min-width: 24rem;
}
.min-w-\[60px\]{
  min-width: 60px;
}
.max-w-6xl{
  max-width: 72rem;
}
.max-w-7xl{
  max-width: 80rem;
}
.max-w-4xl{
  max-width: 56rem;
}
.max-w-lg{
  max-width: 32rem;
}
.max-w-sm{
  max-width: 24rem;
}
.max-w-none{
  max-width: none;
}
.max-w-\[300px\]{
  max-width: 300px;
}
.max-w-\[500px\]{
  max-width: 500px;
}
.max-w-2xl{
  max-width: 42rem;
}
.max-w-xl{
  max-width: 36rem;
}
.max-w-\[400px\]{
  max-width: 400px;
}
.max-w-md{
  max-width: 28rem;
}
.max-w-xs{
  max-width: 20rem;
}
.max-w-full{
  max-width: 100%;
}
.max-w-\[240px\]{
  max-width: 240px;
}
.max-w-\[100\%\]{
  max-width: 100%;
}
.max-w-fit{
  max-width: fit-content;
}
.max-w-\[700px\]{
  max-width: 700px;
}
.max-w-\[390px\]{
  max-width: 390px;
}
.max-w-3xl{
  max-width: 48rem;
}
.max-w-\[600px\]{
  max-width: 600px;
}
.\!max-w-none{
  max-width: none !important;
}
.max-w-\[1000px\]{
  max-width: 1000px;
}
.flex-auto{
  flex: 1 1 auto;
}
.flex-1{
  flex: 1 1 0%;
}
.flex-2{
  flex: 2 2 0%;
}
.flex-none{
  flex: none;
}
.flex-shrink-0{
  flex-shrink: 0;
}
.shrink{
  flex-shrink: 1;
}
.shrink-0{
  flex-shrink: 0;
}
.flex-grow{
  flex-grow: 1;
}
.grow-\[3\]{
  flex-grow: 3;
}
.grow{
  flex-grow: 1;
}
.grow-0{
  flex-grow: 0;
}
.basis-0{
  flex-basis: 0px;
}
.basis-1\/2{
  flex-basis: 50%;
}
.origin-right{
  transform-origin: right;
}
.origin-top-left{
  transform-origin: top left;
}
.origin-center{
  transform-origin: center;
}
.-translate-x-1\/2{
  --tw-translate-x: -50%;
  transform: var(--tw-transform);
}
.translate-x-1\/2{
  --tw-translate-x: 50%;
  transform: var(--tw-transform);
}
.-translate-y-1\/2{
  --tw-translate-y: -50%;
  transform: var(--tw-transform);
}
.-translate-x-full{
  --tw-translate-x: -100%;
  transform: var(--tw-transform);
}
.translate-x-0{
  --tw-translate-x: 0px;
  transform: var(--tw-transform);
}
.-translate-y-1{
  --tw-translate-y: -0.25rem;
  transform: var(--tw-transform);
}
.-translate-x-px{
  --tw-translate-x: -1px;
  transform: var(--tw-transform);
}
.translate-x-full{
  --tw-translate-x: 100%;
  transform: var(--tw-transform);
}
.-translate-y-24{
  --tw-translate-y: -6rem;
  transform: var(--tw-transform);
}
.translate-y-0{
  --tw-translate-y: 0px;
  transform: var(--tw-transform);
}
.translate-y-24{
  --tw-translate-y: 6rem;
  transform: var(--tw-transform);
}
.-translate-x-1\/4{
  --tw-translate-x: -25%;
  transform: var(--tw-transform);
}
.translate-y-1\/2{
  --tw-translate-y: 50%;
  transform: var(--tw-transform);
}
.translate-y-full{
  --tw-translate-y: 100%;
  transform: var(--tw-transform);
}
.rotate-90{
  --tw-rotate: 90deg;
  transform: var(--tw-transform);
}
.rotate-180{
  --tw-rotate: 180deg;
  transform: var(--tw-transform);
}
.rotate-45{
  --tw-rotate: 45deg;
  transform: var(--tw-transform);
}
.-rotate-90{
  --tw-rotate: -90deg;
  transform: var(--tw-transform);
}
.scale-50{
  --tw-scale-x: .5;
  --tw-scale-y: .5;
  transform: var(--tw-transform);
}
.scale-100{
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: var(--tw-transform);
}
.scale-95{
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: var(--tw-transform);
}
.scale-125{
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: var(--tw-transform);
}
.scale-90{
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: var(--tw-transform);
}
.scale-75{
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: var(--tw-transform);
}
.scale-\[102\%\]{
  --tw-scale-x: 102%;
  --tw-scale-y: 102%;
  transform: var(--tw-transform);
}
.scale-\[50\%\]{
  --tw-scale-x: 50%;
  --tw-scale-y: 50%;
  transform: var(--tw-transform);
}
.transform{
  transform: var(--tw-transform);
}
@keyframes spin{
  to{
    transform: rotate(360deg);
  }
}
.animate-spin{
  animation: spin 1s linear infinite;
}
@keyframes scale{
  0%{
    opacity: 0;
    transform: scale(0);
  }
  100%{
    opacity: 1;
    transform: scale(1);
  }
}
.animate-scale{
  animation: scale 75ms ease-in-out 1;
}
@keyframes grow{
  0%, 100%{
    transform: scale(1);
  }
  50%{
    transform: scale(1.5);
  }
}
.animate-grow{
  animation: grow 500ms ease-in-out 1;
}
@keyframes cookies{
  0%{
    transform: scale(0) translateX(92px);
    opacity: 0;
  }
  100%{
    transform: scale(1) translateX(0);
    opacity: 1;
  }
}
.animate-cookies{
  animation: cookies 150ms linear;
}
@keyframes ping{
  75%, 100%{
    transform: scale(2);
    opacity: 0;
  }
}
.animate-ping{
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}
@keyframes pulse{
  50%{
    opacity: .5;
  }
}
.animate-pulse{
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.cursor-pointer{
  cursor: pointer;
}
.cursor-default{
  cursor: default;
}
.cursor-help{
  cursor: help;
}
.cursor-not-allowed{
  cursor: not-allowed;
}
.cursor-grab{
  cursor: grab;
}
.cursor-grabbing{
  cursor: grabbing;
}
.select-none{
  user-select: none;
}
.select-all{
  user-select: all;
}
.resize{
  resize: both;
}
.list-inside{
  list-style-position: inside;
}
.list-disc{
  list-style-type: disc;
}
.appearance-none{
  appearance: none;
}
.auto-rows-min{
  grid-auto-rows: min-content;
}
.grid-cols-1{
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-2{
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.flex-row{
  flex-direction: row;
}
.flex-col{
  flex-direction: column;
}
.flex-col-reverse{
  flex-direction: column-reverse;
}
.flex-wrap{
  flex-wrap: wrap;
}
.flex-nowrap{
  flex-wrap: nowrap;
}
.content-center{
  align-content: center;
}
.items-start{
  align-items: flex-start;
}
.items-end{
  align-items: flex-end;
}
.items-center{
  align-items: center;
}
.items-baseline{
  align-items: baseline;
}
.items-stretch{
  align-items: stretch;
}
.justify-start{
  justify-content: flex-start;
}
.justify-end{
  justify-content: flex-end;
}
.justify-center{
  justify-content: center;
}
.justify-between{
  justify-content: space-between;
}
.justify-around{
  justify-content: space-around;
}
.justify-evenly{
  justify-content: space-evenly;
}
.gap-4{
  gap: 1rem;
}
.gap-8{
  gap: 2rem;
}
.gap-6{
  gap: 1.5rem;
}
.gap-2{
  gap: 0.5rem;
}
.gap-1{
  gap: 0.25rem;
}
.gap-3{
  gap: 0.75rem;
}
.gap-y-8{
  row-gap: 2rem;
}
.gap-x-8{
  column-gap: 2rem;
}
.gap-x-16{
  column-gap: 4rem;
}
.gap-x-4{
  column-gap: 1rem;
}
.space-y-16 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(4rem * var(--tw-space-y-reverse));
}
.space-y-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}
.space-y-20 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(5rem * var(--tw-space-y-reverse));
}
.space-y-8 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}
.space-x-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-y-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
.space-y-1 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}
.space-x-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-y-12 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3rem * var(--tw-space-y-reverse));
}
.space-y-3 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}
.space-y-6 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}
.space-x-8 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-px > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(1px * var(--tw-space-x-reverse));
  margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-1 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-3 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-6 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-y-px > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1px * var(--tw-space-y-reverse));
}
.space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0.125rem * var(--tw-space-x-reverse));
  margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-0 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0.375rem * var(--tw-space-x-reverse));
  margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.125rem * var(--tw-space-y-reverse));
}
.space-y-0 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse));
}
.space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
}
.divide-y > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}
.self-start{
  align-self: flex-start;
}
.self-end{
  align-self: flex-end;
}
.self-center{
  align-self: center;
}
.overflow-auto{
  overflow: auto;
}
.overflow-hidden{
  overflow: hidden;
}
.\!overflow-visible{
  overflow: visible !important;
}
.overflow-scroll{
  overflow: scroll;
}
.overflow-x-auto{
  overflow-x: auto;
}
.overflow-y-auto{
  overflow-y: auto;
}
.overflow-x-hidden{
  overflow-x: hidden;
}
.overflow-x-scroll{
  overflow-x: scroll;
}
.overflow-y-scroll{
  overflow-y: scroll;
}
.truncate{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-ellipsis{
  text-overflow: ellipsis;
}
.text-clip{
  text-overflow: clip;
}
.whitespace-normal{
  white-space: normal;
}
.whitespace-nowrap{
  white-space: nowrap;
}
.whitespace-pre-line{
  white-space: pre-line;
}
.whitespace-pre-wrap{
  white-space: pre-wrap;
}
.break-words{
  overflow-wrap: break-word;
}
.rounded-full{
  border-radius: 9999px;
}
.rounded{
  border-radius: 0.25rem;
}
.rounded-lg{
  border-radius: 0.5rem;
}
.rounded-md{
  border-radius: 0.375rem;
}
.rounded-sm{
  border-radius: 0.125rem;
}
.rounded-3xl{
  border-radius: 1.5rem;
}
.rounded-none{
  border-radius: 0px;
}
.\!rounded-none{
  border-radius: 0px !important;
}
.rounded-xl{
  border-radius: 0.75rem;
}
.\!rounded{
  border-radius: 0.25rem !important;
}
.rounded-2xl{
  border-radius: 1rem;
}
.rounded-t{
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.rounded-r{
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.rounded-b{
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.rounded-l-lg{
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.rounded-t-sm{
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
}
.rounded-l{
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.rounded-t-md{
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.rounded-tl{
  border-top-left-radius: 0.25rem;
}
.rounded-bl-none{
  border-bottom-left-radius: 0px;
}
.rounded-tl-none{
  border-top-left-radius: 0px;
}
.rounded-tr{
  border-top-right-radius: 0.25rem;
}
.border{
  border-width: 1px;
}
.border-2{
  border-width: 2px;
}
.border-4{
  border-width: 4px;
}
.border-0{
  border-width: 0px;
}
.border-y{
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.border-l-4{
  border-left-width: 4px;
}
.border-b-2{
  border-bottom-width: 2px;
}
.border-t{
  border-top-width: 1px;
}
.border-b{
  border-bottom-width: 1px;
}
.border-t-2{
  border-top-width: 2px;
}
.border-l{
  border-left-width: 1px;
}
.border-r{
  border-right-width: 1px;
}
.border-r-4{
  border-right-width: 4px;
}
.\!border-t-0{
  border-top-width: 0px !important;
}
.border-l-2{
  border-left-width: 2px;
}
.border-b-0{
  border-bottom-width: 0px;
}
.border-dashed{
  border-style: dashed;
}
.border-dotted{
  border-style: dotted;
}
.border-none{
  border-style: none;
}
.\!border-none{
  border-style: none !important;
}
.border-secondary-400{
  --tw-border-opacity: 1;
  border-color: rgb(255 95 139 / var(--tw-border-opacity));
}
.border-secondary-600{
  --tw-border-opacity: 1;
  border-color: rgb(205 29 91 / var(--tw-border-opacity));
}
.border-gray-200{
  --tw-border-opacity: 1;
  border-color: rgb(229 235 234 / var(--tw-border-opacity));
}
.border-transparent{
  border-color: transparent;
}
.border-primary-300{
  --tw-border-opacity: 1;
  border-color: rgb(152 217 206 / var(--tw-border-opacity));
}
.border-gray-300{
  --tw-border-opacity: 1;
  border-color: rgb(209 219 217 / var(--tw-border-opacity));
}
.border-gray-900{
  --tw-border-opacity: 1;
  border-color: rgb(17 39 35 / var(--tw-border-opacity));
}
.border-primary-600{
  --tw-border-opacity: 1;
  border-color: rgb(33 128 114 / var(--tw-border-opacity));
}
.border-secondary-700{
  --tw-border-opacity: 1;
  border-color: rgb(195 13 81 / var(--tw-border-opacity));
}
.border-secondary-300{
  --tw-border-opacity: 1;
  border-color: rgb(255 159 185 / var(--tw-border-opacity));
}
.border-blue-300{
  --tw-border-opacity: 1;
  border-color: rgb(164 204 242 / var(--tw-border-opacity));
}
.border-primary-200{
  --tw-border-opacity: 1;
  border-color: rgb(186 237 229 / var(--tw-border-opacity));
}
.border-gray-600{
  --tw-border-opacity: 1;
  border-color: rgb(75 99 95 / var(--tw-border-opacity));
}
.border-secondary-500{
  --tw-border-opacity: 1;
  border-color: rgb(226 63 108 / var(--tw-border-opacity));
}
.border-blue-400{
  --tw-border-opacity: 1;
  border-color: rgb(103 170 234 / var(--tw-border-opacity));
}
.border-primary-400{
  --tw-border-opacity: 1;
  border-color: rgb(84 192 173 / var(--tw-border-opacity));
}
.border-primary-500{
  --tw-border-opacity: 1;
  border-color: rgb(49 158 139 / var(--tw-border-opacity));
}
.border-primary-100{
  --tw-border-opacity: 1;
  border-color: rgb(228 250 246 / var(--tw-border-opacity));
}
.border-secondary-100{
  --tw-border-opacity: 1;
  border-color: rgb(255 227 234 / var(--tw-border-opacity));
}
.border-gender-none-800{
  --tw-border-opacity: 1;
  border-color: rgb(133 77 14 / var(--tw-border-opacity));
}
.border-gender-female-800{
  --tw-border-opacity: 1;
  border-color: rgb(17 94 89 / var(--tw-border-opacity));
}
.border-gender-male-800{
  --tw-border-opacity: 1;
  border-color: rgb(91 33 182 / var(--tw-border-opacity));
}
.border-gray-400{
  --tw-border-opacity: 1;
  border-color: rgb(156 175 172 / var(--tw-border-opacity));
}
.border-gray-500{
  --tw-border-opacity: 1;
  border-color: rgb(107 128 124 / var(--tw-border-opacity));
}
.border-white{
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
.border-red-400{
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity));
}
.border-gray-700{
  --tw-border-opacity: 1;
  border-color: rgb(55 81 76 / var(--tw-border-opacity));
}
.border-\[\#E45300\]{
  --tw-border-opacity: 1;
  border-color: rgb(228 83 0 / var(--tw-border-opacity));
}
.border-red-500{
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}
.border-gray-100{
  --tw-border-opacity: 1;
  border-color: rgb(243 246 245 / var(--tw-border-opacity));
}
.border-gray-50{
  --tw-border-opacity: 1;
  border-color: rgb(249 251 251 / var(--tw-border-opacity));
}
.border-orange-200{
  --tw-border-opacity: 1;
  border-color: rgb(254 215 170 / var(--tw-border-opacity));
}
.\!border-transparent{
  border-color: transparent !important;
}
.border-black{
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}
.border-t-gray-200{
  --tw-border-opacity: 1;
  border-top-color: rgb(229 235 234 / var(--tw-border-opacity));
}
.border-b-gray-400{
  --tw-border-opacity: 1;
  border-bottom-color: rgb(156 175 172 / var(--tw-border-opacity));
}
.bg-primary-500{
  --tw-bg-opacity: 1;
  background-color: rgb(49 158 139 / var(--tw-bg-opacity));
}
.bg-red-100{
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}
.bg-white{
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-gray-50{
  --tw-bg-opacity: 1;
  background-color: rgb(249 251 251 / var(--tw-bg-opacity));
}
.bg-secondary-400{
  --tw-bg-opacity: 1;
  background-color: rgb(255 95 139 / var(--tw-bg-opacity));
}
.bg-slate-100{
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}
.\!bg-primary-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(228 250 246 / var(--tw-bg-opacity)) !important;
}
.bg-gray-500{
  --tw-bg-opacity: 1;
  background-color: rgb(107 128 124 / var(--tw-bg-opacity));
}
.bg-primary-300{
  --tw-bg-opacity: 1;
  background-color: rgb(152 217 206 / var(--tw-bg-opacity));
}
.bg-gray-900\/30{
  background-color: rgb(17 39 35 / 0.3);
}
.bg-gray-100{
  --tw-bg-opacity: 1;
  background-color: rgb(243 246 245 / var(--tw-bg-opacity));
}
.bg-primary-100{
  --tw-bg-opacity: 1;
  background-color: rgb(228 250 246 / var(--tw-bg-opacity));
}
.bg-secondary-100{
  --tw-bg-opacity: 1;
  background-color: rgb(255 227 234 / var(--tw-bg-opacity));
}
.bg-secondary-300{
  --tw-bg-opacity: 1;
  background-color: rgb(255 159 185 / var(--tw-bg-opacity));
}
.bg-blue-300{
  --tw-bg-opacity: 1;
  background-color: rgb(164 204 242 / var(--tw-bg-opacity));
}
.bg-primary-400{
  --tw-bg-opacity: 1;
  background-color: rgb(84 192 173 / var(--tw-bg-opacity));
}
.bg-transparent{
  background-color: transparent;
}
.bg-gray-900\/70{
  background-color: rgb(17 39 35 / 0.7);
}
.bg-gray-600{
  --tw-bg-opacity: 1;
  background-color: rgb(75 99 95 / var(--tw-bg-opacity));
}
.bg-gray-400{
  --tw-bg-opacity: 1;
  background-color: rgb(156 175 172 / var(--tw-bg-opacity));
}
.bg-secondary-500{
  --tw-bg-opacity: 1;
  background-color: rgb(226 63 108 / var(--tw-bg-opacity));
}
.bg-gray-200{
  --tw-bg-opacity: 1;
  background-color: rgb(229 235 234 / var(--tw-bg-opacity));
}
.bg-secondary-50{
  --tw-bg-opacity: 1;
  background-color: rgb(255 241 244 / var(--tw-bg-opacity));
}
.bg-blue-50{
  --tw-bg-opacity: 1;
  background-color: rgb(241 247 254 / var(--tw-bg-opacity));
}
.bg-gray-900\/50{
  background-color: rgb(17 39 35 / 0.5);
}
.\!bg-blue-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(192 218 247 / var(--tw-bg-opacity)) !important;
}
.bg-primary-200{
  --tw-bg-opacity: 1;
  background-color: rgb(186 237 229 / var(--tw-bg-opacity));
}
.bg-gray-800{
  --tw-bg-opacity: 1;
  background-color: rgb(31 55 51 / var(--tw-bg-opacity));
}
.bg-primary-700{
  --tw-bg-opacity: 1;
  background-color: rgb(30 103 93 / var(--tw-bg-opacity));
}
.bg-secondary-500\/80{
  background-color: rgb(226 63 108 / 0.8);
}
.bg-amber-500{
  --tw-bg-opacity: 1;
  background-color: rgb(245 158 11 / var(--tw-bg-opacity));
}
.bg-gray-900{
  --tw-bg-opacity: 1;
  background-color: rgb(17 39 35 / var(--tw-bg-opacity));
}
.bg-slate-500{
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity));
}
.bg-blue-400{
  --tw-bg-opacity: 1;
  background-color: rgb(103 170 234 / var(--tw-bg-opacity));
}
.bg-blue-100{
  --tw-bg-opacity: 1;
  background-color: rgb(217 233 249 / var(--tw-bg-opacity));
}
.bg-secondary-800{
  --tw-bg-opacity: 1;
  background-color: rgb(163 14 74 / var(--tw-bg-opacity));
}
.bg-secondary-600{
  --tw-bg-opacity: 1;
  background-color: rgb(205 29 91 / var(--tw-bg-opacity));
}
.bg-blue-600{
  --tw-bg-opacity: 1;
  background-color: rgb(47 98 152 / var(--tw-bg-opacity));
}
.bg-gray-400\/10{
  background-color: rgb(156 175 172 / 0.1);
}
.bg-secondary-200{
  --tw-bg-opacity: 1;
  background-color: rgb(255 204 217 / var(--tw-bg-opacity));
}
.bg-yellow-100{
  --tw-bg-opacity: 1;
  background-color: rgb(254 249 195 / var(--tw-bg-opacity));
}
.bg-orange-100{
  --tw-bg-opacity: 1;
  background-color: rgb(255 237 213 / var(--tw-bg-opacity));
}
.bg-red-200{
  --tw-bg-opacity: 1;
  background-color: rgb(254 202 202 / var(--tw-bg-opacity));
}
.bg-primary-50{
  --tw-bg-opacity: 1;
  background-color: rgb(242 251 249 / var(--tw-bg-opacity));
}
.bg-gray-300{
  --tw-bg-opacity: 1;
  background-color: rgb(209 219 217 / var(--tw-bg-opacity));
}
.bg-primary-400\/50{
  background-color: rgb(84 192 173 / 0.5);
}
.bg-secondary-400\/50{
  background-color: rgb(255 95 139 / 0.5);
}
.bg-black{
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
.bg-gray-900\/5{
  background-color: rgb(17 39 35 / 0.05);
}
.bg-blue-500{
  --tw-bg-opacity: 1;
  background-color: rgb(57 122 184 / var(--tw-bg-opacity));
}
.bg-inherit{
  background-color: inherit;
}
.bg-gender-male-500{
  --tw-bg-opacity: 1;
  background-color: rgb(139 92 246 / var(--tw-bg-opacity));
}
.bg-gender-female-500{
  --tw-bg-opacity: 1;
  background-color: rgb(20 184 166 / var(--tw-bg-opacity));
}
.bg-gender-none-500{
  --tw-bg-opacity: 1;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity));
}
.bg-primary-500\/50{
  background-color: rgb(49 158 139 / 0.5);
}
.bg-opacity-50{
  --tw-bg-opacity: 0.5;
}
.bg-opacity-20{
  --tw-bg-opacity: 0.2;
}
.bg-opacity-75{
  --tw-bg-opacity: 0.75;
}
.bg-opacity-25{
  --tw-bg-opacity: 0.25;
}
.bg-gradient-to-br{
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}
.bg-gradient-to-r{
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}
.from-white{
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(255 255 255 / 0));
}
.from-gray-700{
  --tw-gradient-from: #37514C;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(55 81 76 / 0));
}
.from-secondary-500{
  --tw-gradient-from: #E23F6C;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(226 63 108 / 0));
}
.from-secondary-100{
  --tw-gradient-from: #FFE3EA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(255 227 234 / 0));
}
.from-blue-100{
  --tw-gradient-from: #D9E9F9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(217 233 249 / 0));
}
.from-primary-100{
  --tw-gradient-from: #E4FAF6;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(228 250 246 / 0));
}
.from-primary-400{
  --tw-gradient-from: #54C0AD;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(84 192 173 / 0));
}
.from-gender-none-600{
  --tw-gradient-from: #ca8a04;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(202 138 4 / 0));
}
.from-gender-female-600{
  --tw-gradient-from: #0d9488;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(13 148 136 / 0));
}
.from-gender-male-600{
  --tw-gradient-from: #7c3aed;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(124 58 237 / 0));
}
.from-secondary-300{
  --tw-gradient-from: #FF9FB9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(255 159 185 / 0));
}
.from-red-200{
  --tw-gradient-from: #fecaca;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(254 202 202 / 0));
}
.from-secondary-50{
  --tw-gradient-from: #FFF1F4;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(255 241 244 / 0));
}
.from-gray-800{
  --tw-gradient-from: #1F3733;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(31 55 51 / 0));
}
.from-secondary-400{
  --tw-gradient-from: #FF5F8B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(255 95 139 / 0));
}
.from-gray-200{
  --tw-gradient-from: #E5EBEA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(229 235 234 / 0));
}
.from-blue-300{
  --tw-gradient-from: #A4CCF2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(164 204 242 / 0));
}
.from-primary-300{
  --tw-gradient-from: #98D9CE;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(152 217 206 / 0));
}
.from-secondary-200{
  --tw-gradient-from: #FFCCD9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(255 204 217 / 0));
}
.via-secondary-500{
  --tw-gradient-stops: var(--tw-gradient-from), #E23F6C, var(--tw-gradient-to, rgb(226 63 108 / 0));
}
.to-gray-50{
  --tw-gradient-to: #F9FBFB;
}
.to-gray-800{
  --tw-gradient-to: #1F3733;
}
.to-secondary-600{
  --tw-gradient-to: #CD1D5B;
}
.to-secondary-200{
  --tw-gradient-to: #FFCCD9;
}
.to-blue-200{
  --tw-gradient-to: #C0DAF7;
}
.to-primary-200{
  --tw-gradient-to: #BAEDE5;
}
.to-primary-600{
  --tw-gradient-to: #218072;
}
.to-gender-none-700{
  --tw-gradient-to: #a16207;
}
.to-gender-female-700{
  --tw-gradient-to: #0f766e;
}
.to-gender-male-700{
  --tw-gradient-to: #6d28d9;
}
.to-secondary-400{
  --tw-gradient-to: #FF5F8B;
}
.to-red-300{
  --tw-gradient-to: #fca5a5;
}
.to-secondary-100{
  --tw-gradient-to: #FFE3EA;
}
.to-gray-900{
  --tw-gradient-to: #112723;
}
.to-secondary-500{
  --tw-gradient-to: #E23F6C;
}
.to-secondary-300{
  --tw-gradient-to: #FF9FB9;
}
.to-gray-300{
  --tw-gradient-to: #D1DBD9;
}
.to-blue-400{
  --tw-gradient-to: #67AAEA;
}
.to-primary-300{
  --tw-gradient-to: #98D9CE;
}
.to-primary-400{
  --tw-gradient-to: #54C0AD;
}
.bg-cover{
  background-size: cover;
}
.bg-center{
  background-position: center;
}
.p-8{
  padding: 2rem;
}
.p-4{
  padding: 1rem;
}
.p-2{
  padding: 0.5rem;
}
.p-px{
  padding: 1px;
}
.p-3{
  padding: 0.75rem;
}
.p-0{
  padding: 0px;
}
.p-6{
  padding: 1.5rem;
}
.p-1{
  padding: 0.25rem;
}
.\!p-5{
  padding: 1.25rem !important;
}
.\!p-4{
  padding: 1rem !important;
}
.p-10{
  padding: 2.5rem;
}
.\!p-0{
  padding: 0px !important;
}
.\!p-1{
  padding: 0.25rem !important;
}
.p-5{
  padding: 1.25rem;
}
.p-1\.5{
  padding: 0.375rem;
}
.p-12{
  padding: 3rem;
}
.p-7{
  padding: 1.75rem;
}
.p-0\.5{
  padding: 0.125rem;
}
.px-12{
  padding-left: 3rem;
  padding-right: 3rem;
}
.px-24{
  padding-left: 6rem;
  padding-right: 6rem;
}
.px-5{
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.py-14{
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}
.py-4{
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.px-2{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-4{
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-3{
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.py-2{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-1{
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.px-1{
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.py-px{
  padding-top: 1px;
  padding-bottom: 1px;
}
.px-0\.5{
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}
.px-0{
  padding-left: 0px;
  padding-right: 0px;
}
.py-0\.5{
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.py-0{
  padding-top: 0px;
  padding-bottom: 0px;
}
.\!px-5{
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}
.py-12{
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.py-8{
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.\!py-0{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.py-6{
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.px-8{
  padding-left: 2rem;
  padding-right: 2rem;
}
.\!px-0{
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.py-3{
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-\[6px\]{
  padding-top: 6px;
  padding-bottom: 6px;
}
.px-\[8px\]{
  padding-left: 8px;
  padding-right: 8px;
}
.px-16{
  padding-left: 4rem;
  padding-right: 4rem;
}
.px-6{
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.px-1\.5{
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
.py-1\.5{
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
.\!py-4{
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.py-16{
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.py-\[13px\]{
  padding-top: 13px;
  padding-bottom: 13px;
}
.py-2\.5{
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.py-5{
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.pb-16{
  padding-bottom: 4rem;
}
.pt-7{
  padding-top: 1.75rem;
}
.pb-4{
  padding-bottom: 1rem;
}
.pb-5{
  padding-bottom: 1.25rem;
}
.pb-8{
  padding-bottom: 2rem;
}
.pl-4{
  padding-left: 1rem;
}
.pb-12{
  padding-bottom: 3rem;
}
.pt-6{
  padding-top: 1.5rem;
}
.pl-6{
  padding-left: 1.5rem;
}
.pr-4{
  padding-right: 1rem;
}
.pb-2{
  padding-bottom: 0.5rem;
}
.pt-4{
  padding-top: 1rem;
}
.pb-14{
  padding-bottom: 3.5rem;
}
.pt-2{
  padding-top: 0.5rem;
}
.pb-0\.5{
  padding-bottom: 0.125rem;
}
.pb-0{
  padding-bottom: 0px;
}
.pt-1{
  padding-top: 0.25rem;
}
.pl-10{
  padding-left: 2.5rem;
}
.pb-6{
  padding-bottom: 1.5rem;
}
.pl-1{
  padding-left: 0.25rem;
}
.pr-2{
  padding-right: 0.5rem;
}
.pr-0{
  padding-right: 0px;
}
.pl-7{
  padding-left: 1.75rem;
}
.pr-3{
  padding-right: 0.75rem;
}
.pl-2{
  padding-left: 0.5rem;
}
.pt-3{
  padding-top: 0.75rem;
}
.pl-8{
  padding-left: 2rem;
}
.pt-8{
  padding-top: 2rem;
}
.pb-1{
  padding-bottom: 0.25rem;
}
.pr-1{
  padding-right: 0.25rem;
}
.\!pb-6{
  padding-bottom: 1.5rem !important;
}
.pl-5{
  padding-left: 1.25rem;
}
.pl-3{
  padding-left: 0.75rem;
}
.pt-0{
  padding-top: 0px;
}
.pt-12{
  padding-top: 3rem;
}
.pb-10{
  padding-bottom: 2.5rem;
}
.\!pl-6{
  padding-left: 1.5rem !important;
}
.pr-6{
  padding-right: 1.5rem;
}
.pb-3{
  padding-bottom: 0.75rem;
}
.pt-0\.5{
  padding-top: 0.125rem;
}
.pt-16{
  padding-top: 4rem;
}
.pb-24{
  padding-bottom: 6rem;
}
.pt-10{
  padding-top: 2.5rem;
}
.pl-28{
  padding-left: 7rem;
}
.pb-\[56\.25\%\]{
  padding-bottom: 56.25%;
}
.pr-5{
  padding-right: 1.25rem;
}
.\!pb-0{
  padding-bottom: 0px !important;
}
.text-left{
  text-align: left;
}
.\!text-left{
  text-align: left !important;
}
.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}
.text-justify{
  text-align: justify;
}
.align-middle{
  vertical-align: middle;
}
.font-title{
  font-family: 'Work Sans', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto;
}
.font-sans{
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto;
}
.text-xl{
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-lg{
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-sm{
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xs{
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-2xs{
  font-size: .6rem;
}
.text-base{
  font-size: 1rem;
  line-height: 1.5rem;
}
.\!text-xs{
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}
.\!text-2xs{
  font-size: .6rem !important;
}
.text-4xl{
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.text-5xl{
  font-size: 3rem;
  line-height: 1;
}
.text-2xl{
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-3xl{
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.text-\[13px\]{
  font-size: 13px;
}
.font-semibold{
  font-weight: 600;
}
.font-bold{
  font-weight: 700;
}
.font-medium{
  font-weight: 500;
}
.font-normal{
  font-weight: 400;
}
.\!font-normal{
  font-weight: 400 !important;
}
.font-light{
  font-weight: 300;
}
.uppercase{
  text-transform: uppercase;
}
.lowercase{
  text-transform: lowercase;
}
.capitalize{
  text-transform: capitalize;
}
.normal-case{
  text-transform: none;
}
.italic{
  font-style: italic;
}
*, ::before, ::after{
  --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
  --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
  --tw-font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}
.ordinal{
  --tw-ordinal: ordinal;
  font-variant-numeric: var(--tw-font-variant-numeric);
}
.tabular-nums{
  --tw-numeric-spacing: tabular-nums;
  font-variant-numeric: var(--tw-font-variant-numeric);
}
.leading-none{
  line-height: 1;
}
.leading-relaxed{
  line-height: 1.625;
}
.\!leading-relaxed{
  line-height: 1.625 !important;
}
.leading-snug{
  line-height: 1.375;
}
.leading-tight{
  line-height: 1.25;
}
.leading-6{
  line-height: 1.5rem;
}
.tracking-wide{
  letter-spacing: 0.025em;
}
.text-gray-500{
  --tw-text-opacity: 1;
  color: rgb(107 128 124 / var(--tw-text-opacity));
}
.text-gray-400{
  --tw-text-opacity: 1;
  color: rgb(156 175 172 / var(--tw-text-opacity));
}
.text-primary-500{
  --tw-text-opacity: 1;
  color: rgb(49 158 139 / var(--tw-text-opacity));
}
.text-primary-700{
  --tw-text-opacity: 1;
  color: rgb(30 103 93 / var(--tw-text-opacity));
}
.text-primary-600{
  --tw-text-opacity: 1;
  color: rgb(33 128 114 / var(--tw-text-opacity));
}
.text-white{
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.text-gray-800{
  --tw-text-opacity: 1;
  color: rgb(31 55 51 / var(--tw-text-opacity));
}
.text-gray-700{
  --tw-text-opacity: 1;
  color: rgb(55 81 76 / var(--tw-text-opacity));
}
.text-secondary-500{
  --tw-text-opacity: 1;
  color: rgb(226 63 108 / var(--tw-text-opacity));
}
.text-gray-900{
  --tw-text-opacity: 1;
  color: rgb(17 39 35 / var(--tw-text-opacity));
}
.text-gray-600{
  --tw-text-opacity: 1;
  color: rgb(75 99 95 / var(--tw-text-opacity));
}
.text-gray-50{
  --tw-text-opacity: 1;
  color: rgb(249 251 251 / var(--tw-text-opacity));
}
.text-primary-400{
  --tw-text-opacity: 1;
  color: rgb(84 192 173 / var(--tw-text-opacity));
}
.text-secondary-900{
  --tw-text-opacity: 1;
  color: rgb(139 16 70 / var(--tw-text-opacity));
}
.text-secondary-700{
  --tw-text-opacity: 1;
  color: rgb(195 13 81 / var(--tw-text-opacity));
}
.text-blue-700{
  --tw-text-opacity: 1;
  color: rgb(38 79 125 / var(--tw-text-opacity));
}
.text-primary-900{
  --tw-text-opacity: 1;
  color: rgb(28 69 64 / var(--tw-text-opacity));
}
.text-gender-male-600{
  --tw-text-opacity: 1;
  color: rgb(124 58 237 / var(--tw-text-opacity));
}
.text-gender-female-600{
  --tw-text-opacity: 1;
  color: rgb(13 148 136 / var(--tw-text-opacity));
}
.text-secondary-600{
  --tw-text-opacity: 1;
  color: rgb(205 29 91 / var(--tw-text-opacity));
}
.\!text-gray-400{
  --tw-text-opacity: 1 !important;
  color: rgb(156 175 172 / var(--tw-text-opacity)) !important;
}
.text-red-900{
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity));
}
.text-primary-200{
  --tw-text-opacity: 1;
  color: rgb(186 237 229 / var(--tw-text-opacity));
}
.text-secondary-400{
  --tw-text-opacity: 1;
  color: rgb(255 95 139 / var(--tw-text-opacity));
}
.text-gray-100{
  --tw-text-opacity: 1;
  color: rgb(243 246 245 / var(--tw-text-opacity));
}
.text-primary-100{
  --tw-text-opacity: 1;
  color: rgb(228 250 246 / var(--tw-text-opacity));
}
.\!text-gray-50{
  --tw-text-opacity: 1 !important;
  color: rgb(249 251 251 / var(--tw-text-opacity)) !important;
}
.text-gray-300{
  --tw-text-opacity: 1;
  color: rgb(209 219 217 / var(--tw-text-opacity));
}
.text-slate-500{
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}
.text-neutral-600{
  --tw-text-opacity: 1;
  color: rgb(82 82 82 / var(--tw-text-opacity));
}
.\!text-gray-600{
  --tw-text-opacity: 1 !important;
  color: rgb(75 99 95 / var(--tw-text-opacity)) !important;
}
.text-orange-400{
  --tw-text-opacity: 1;
  color: rgb(251 146 60 / var(--tw-text-opacity));
}
.text-blue-500{
  --tw-text-opacity: 1;
  color: rgb(57 122 184 / var(--tw-text-opacity));
}
.text-blue-400{
  --tw-text-opacity: 1;
  color: rgb(103 170 234 / var(--tw-text-opacity));
}
.\!text-gray-500{
  --tw-text-opacity: 1 !important;
  color: rgb(107 128 124 / var(--tw-text-opacity)) !important;
}
.text-red-600{
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}
.text-emerald-900{
  --tw-text-opacity: 1;
  color: rgb(6 78 59 / var(--tw-text-opacity));
}
.text-primary-300{
  --tw-text-opacity: 1;
  color: rgb(152 217 206 / var(--tw-text-opacity));
}
.text-gray-200{
  --tw-text-opacity: 1;
  color: rgb(229 235 234 / var(--tw-text-opacity));
}
.text-secondary-300{
  --tw-text-opacity: 1;
  color: rgb(255 159 185 / var(--tw-text-opacity));
}
.\!text-black{
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}
.text-red-400{
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}
.text-red-500{
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}
.\!text-gray-800{
  --tw-text-opacity: 1 !important;
  color: rgb(31 55 51 / var(--tw-text-opacity)) !important;
}
.underline{
  text-decoration-line: underline;
}
.overline{
  text-decoration-line: overline;
}
.no-underline{
  text-decoration-line: none;
}
.decoration-gray-400{
  text-decoration-color: #9CAFAC;
}
.decoration-dotted{
  text-decoration-style: dotted;
}
.decoration-dashed{
  text-decoration-style: dashed;
}
.underline-offset-4{
  text-underline-offset: 4px;
}
.opacity-0{
  opacity: 0;
}
.opacity-100{
  opacity: 1;
}
.opacity-25{
  opacity: 0.25;
}
.opacity-75{
  opacity: 0.75;
}
.opacity-50{
  opacity: 0.5;
}
.opacity-\[0\.02\]{
  opacity: 0.02;
}
.opacity-\[0\.2\]{
  opacity: 0.2;
}
.opacity-70{
  opacity: 0.7;
}
.shadow{
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-2xl{
  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-sm{
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-md{
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-xl{
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-none{
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.outline-none{
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.outline{
  outline-style: solid;
}
.ring-1{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.ring{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.ring-primary-500{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(49 158 139 / var(--tw-ring-opacity));
}
.ring-primary-400{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(84 192 173 / var(--tw-ring-opacity));
}
.ring-gray-300{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 219 217 / var(--tw-ring-opacity));
}
.blur{
  --tw-blur: blur(8px);
  filter: var(--tw-filter);
}
.drop-shadow-sm{
  --tw-drop-shadow: drop-shadow(0 1px 1px rgb(0 0 0 / 0.05));
  filter: var(--tw-filter);
}
.drop-shadow{
  --tw-drop-shadow: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  filter: var(--tw-filter);
}
.grayscale{
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-filter);
}
.filter{
  filter: var(--tw-filter);
}
.\!filter{
  filter: var(--tw-filter) !important;
}
.transition{
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-colors{
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-\[width\]{
  transition-property: width;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-all{
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.delay-300{
  transition-delay: 300ms;
}
.delay-1000{
  transition-delay: 1000ms;
}
.delay-75{
  transition-delay: 75ms;
}
.duration-200{
  transition-duration: 200ms;
}
.duration-100{
  transition-duration: 100ms;
}
.duration-75{
  transition-duration: 75ms;
}
.duration-300{
  transition-duration: 300ms;
}
.duration-150{
  transition-duration: 150ms;
}
.duration-1000{
  transition-duration: 1000ms;
}
.ease-out{
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.ease-in-out{
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.pattern-bg-transparent{
  --pattern-bg-color: transparent;
}
.pattern-secondary-500{
  --pattern-color: #E23F6C;
  --pattern-color-55: #E23F6C55;
  --pattern-color-77: #E23F6C77;
}
.pattern-opacity-100{
  --pattern-opacity: 1;
}
.\[comp-review\:send-notifications\]{
  comp-review: send-notifications;
}
.\[app\:set-onboarding-state\]{
  app: set-onboarding-state;
}
.\[db\:clone\]{
  db: clone;
}
.\[dev\:env\]{
  dev: env;
}
.\[dev\:saml-setup\]{
  dev: saml-setup;
}
.\[hris\:clone\]{
  hris: clone;
}
.\[once\:align-employee-currencies\]{
  once: align-employee-currencies;
}
.\[once\:refresh-comp-review-converted-amounts\]{
  once: refresh-comp-review-converted-amounts;
}
.\[saml\:clone\]{
  saml: clone;
}

/*
 * Globals
 */
html, body, #__next{
  height: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(249 251 251 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(17 39 35 / var(--tw-text-opacity));
  -webkit-font-smoothing: antialiased;
}

/*
 * Turn off number input spinners
 */
/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button{
  margin: 0px;
  -webkit-appearance: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*
 * Disable button appearence (Safari)
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: none;
}

/*
 * Allows hiding some scrollbars
 */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

/*
 * NProgress
 */

/* Display at the bottom of navbar */
#nprogress .bar {
  background-color: #319e8b !important;
  top: 0px !important;
  height: 3px !important;
}

#nprogress .peg {
  display: none !important;
}

/*
 * Text-shadow
 */
.text-shadow {
  text-shadow: 0 1px 2px rgb(0 0 0 / 25%);
}

.hide-scrollbar {
  scrollbar-width: none;
}

/*
 * MDX Editor dialogs
 */
[class^="_primaryButton"],
[class^="_secondaryButton"] {
  border: 1px solid #e5ebea !important;
  color: #112723 !important;
}

/*
 * Antd menus dropdowns
 */
.ant-cascader-menu {
  height: max(200px, 40vh) !important;
  min-width: 16rem !important;
}
.ant-cascader,
.ant-select-selector {
  min-height: 40px !important;
}
.ant-select-sm.ant-select-selection-placeholder {
  inset-inline: 8px !important;
}
.ant-select-sm.ant-cascader {
  min-height: 32px !important;
  font-size: 14px !important;
}
.ant-select-sm .ant-select-selector {
  min-height: 32px !important;
  font-size: 14px !important;
}
.ant-select-sm .ant-select-selection-item {
  font-size: 14px !important;
}

.ant-cascader-checkbox {
  align-self: flex-start !important;
  margin-top: 3px !important;
}
.ant-select-selection-overflow-item > span {
  max-width: 100%;
}
.ant-cascader-menu-item[data-path-key="best-match"] {
  display: none;
}
.ant-cascader-menu-item[data-path-key="custom-jobs"] > .ant-cascader-checkbox {
  display: none;
}
.before\:bg-transparent::before{
  content: var(--tw-content);
  background-color: transparent;
}
.first\:-mt-2:first-child{
  margin-top: -0.5rem;
}
.first\:border-t-2:first-child{
  border-top-width: 2px;
}
.first\:border-t-0:first-child{
  border-top-width: 0px;
}
.first\:border-t:first-child{
  border-top-width: 1px;
}
.first\:border-none:first-child{
  border-style: none;
}
.focus-within\:my-0:focus-within{
  margin-top: 0px;
  margin-bottom: 0px;
}
.focus-within\:border-2:focus-within{
  border-width: 2px;
}
.focus-within\:\!border-primary-500:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgb(49 158 139 / var(--tw-border-opacity)) !important;
}
.focus-within\:text-primary-600:focus-within{
  --tw-text-opacity: 1;
  color: rgb(33 128 114 / var(--tw-text-opacity));
}
.focus-within\:ring:focus-within{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.hover\:z-20:hover{
  z-index: 20;
}
.hover\:scale-105:hover{
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: var(--tw-transform);
}
.hover\:scale-150:hover{
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
  transform: var(--tw-transform);
}
.hover\:scale-125:hover{
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: var(--tw-transform);
}
.hover\:scale-110:hover{
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: var(--tw-transform);
}
.hover\:scale-y-100:hover{
  --tw-scale-y: 1;
  transform: var(--tw-transform);
}
.hover\:border-secondary-600:hover{
  --tw-border-opacity: 1;
  border-color: rgb(205 29 91 / var(--tw-border-opacity));
}
.hover\:border-primary-200:hover{
  --tw-border-opacity: 1;
  border-color: rgb(186 237 229 / var(--tw-border-opacity));
}
.hover\:border-primary-400:hover{
  --tw-border-opacity: 1;
  border-color: rgb(84 192 173 / var(--tw-border-opacity));
}
.hover\:border-secondary-200:hover{
  --tw-border-opacity: 1;
  border-color: rgb(255 204 217 / var(--tw-border-opacity));
}
.hover\:border-secondary-400:hover{
  --tw-border-opacity: 1;
  border-color: rgb(255 95 139 / var(--tw-border-opacity));
}
.hover\:border-gray-900:hover{
  --tw-border-opacity: 1;
  border-color: rgb(17 39 35 / var(--tw-border-opacity));
}
.hover\:border-secondary-500:hover{
  --tw-border-opacity: 1;
  border-color: rgb(226 63 108 / var(--tw-border-opacity));
}
.hover\:border-green-200:hover{
  --tw-border-opacity: 1;
  border-color: rgb(187 247 208 / var(--tw-border-opacity));
}
.hover\:border-gray-800:hover{
  --tw-border-opacity: 1;
  border-color: rgb(31 55 51 / var(--tw-border-opacity));
}
.hover\:border-primary-700:hover{
  --tw-border-opacity: 1;
  border-color: rgb(30 103 93 / var(--tw-border-opacity));
}
.hover\:border-secondary-700:hover{
  --tw-border-opacity: 1;
  border-color: rgb(195 13 81 / var(--tw-border-opacity));
}
.hover\:border-blue-500:hover{
  --tw-border-opacity: 1;
  border-color: rgb(57 122 184 / var(--tw-border-opacity));
}
.hover\:border-primary-600:hover{
  --tw-border-opacity: 1;
  border-color: rgb(33 128 114 / var(--tw-border-opacity));
}
.hover\:border-secondary-800:hover{
  --tw-border-opacity: 1;
  border-color: rgb(163 14 74 / var(--tw-border-opacity));
}
.hover\:border-gray-700:hover{
  --tw-border-opacity: 1;
  border-color: rgb(55 81 76 / var(--tw-border-opacity));
}
.hover\:border-gray-200:hover{
  --tw-border-opacity: 1;
  border-color: rgb(229 235 234 / var(--tw-border-opacity));
}
.hover\:border-gray-300:hover{
  --tw-border-opacity: 1;
  border-color: rgb(209 219 217 / var(--tw-border-opacity));
}
.hover\:bg-primary-50:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(242 251 249 / var(--tw-bg-opacity));
}
.hover\:bg-primary-100:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(228 250 246 / var(--tw-bg-opacity));
}
.hover\:bg-primary-200:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(186 237 229 / var(--tw-bg-opacity));
}
.hover\:bg-gray-50:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(249 251 251 / var(--tw-bg-opacity));
}
.hover\:bg-gray-700:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(55 81 76 / var(--tw-bg-opacity));
}
.hover\:bg-primary-400:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(84 192 173 / var(--tw-bg-opacity));
}
.hover\:\!bg-primary-400:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(84 192 173 / var(--tw-bg-opacity)) !important;
}
.hover\:bg-transparent:hover{
  background-color: transparent;
}
.hover\:bg-secondary-400:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(255 95 139 / var(--tw-bg-opacity));
}
.hover\:bg-green-50:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 244 / var(--tw-bg-opacity));
}
.hover\:bg-gray-100:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(243 246 245 / var(--tw-bg-opacity));
}
.hover\:bg-secondary-50:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(255 241 244 / var(--tw-bg-opacity));
}
.hover\:bg-white:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.hover\:bg-red-100:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}
.hover\:bg-gray-900\/10:hover{
  background-color: rgb(17 39 35 / 0.1);
}
.hover\:from-secondary-400:hover{
  --tw-gradient-from: #FF5F8B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(255 95 139 / 0));
}
.hover\:from-primary-600:hover{
  --tw-gradient-from: #218072;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(33 128 114 / 0));
}
.hover\:from-secondary-500:hover{
  --tw-gradient-from: #E23F6C;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(226 63 108 / 0));
}
.hover\:from-blue-400:hover{
  --tw-gradient-from: #67AAEA;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(103 170 234 / 0));
}
.hover\:from-primary-300:hover{
  --tw-gradient-from: #98D9CE;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(152 217 206 / 0));
}
.hover\:from-secondary-300:hover{
  --tw-gradient-from: #FF9FB9;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(255 159 185 / 0));
}
.hover\:from-secondary-600:hover{
  --tw-gradient-from: #CD1D5B;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(205 29 91 / 0));
}
.hover\:to-secondary-500:hover{
  --tw-gradient-to: #E23F6C;
}
.hover\:to-primary-600:hover{
  --tw-gradient-to: #218072;
}
.hover\:to-secondary-600:hover{
  --tw-gradient-to: #CD1D5B;
}
.hover\:to-blue-400:hover{
  --tw-gradient-to: #67AAEA;
}
.hover\:to-primary-400:hover{
  --tw-gradient-to: #54C0AD;
}
.hover\:to-secondary-400:hover{
  --tw-gradient-to: #FF5F8B;
}
.hover\:to-secondary-700:hover{
  --tw-gradient-to: #C30D51;
}
.hover\:text-gray-100:hover{
  --tw-text-opacity: 1;
  color: rgb(243 246 245 / var(--tw-text-opacity));
}
.hover\:text-white:hover{
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.hover\:text-gray-900:hover{
  --tw-text-opacity: 1;
  color: rgb(17 39 35 / var(--tw-text-opacity));
}
.hover\:text-gray-800:hover{
  --tw-text-opacity: 1;
  color: rgb(31 55 51 / var(--tw-text-opacity));
}
.hover\:text-primary-500:hover{
  --tw-text-opacity: 1;
  color: rgb(49 158 139 / var(--tw-text-opacity));
}
.hover\:text-red-500:hover{
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}
.hover\:text-gray-700:hover{
  --tw-text-opacity: 1;
  color: rgb(55 81 76 / var(--tw-text-opacity));
}
.hover\:text-primary-600:hover{
  --tw-text-opacity: 1;
  color: rgb(33 128 114 / var(--tw-text-opacity));
}
.hover\:underline:hover{
  text-decoration-line: underline;
}
.hover\:opacity-100:hover{
  opacity: 1;
}
.hover\:opacity-70:hover{
  opacity: 0.7;
}
.hover\:shadow-xl:hover{
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:ring-gray-900:hover{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(17 39 35 / var(--tw-ring-opacity));
}
.focus\:border-primary-700:focus{
  --tw-border-opacity: 1;
  border-color: rgb(30 103 93 / var(--tw-border-opacity));
}
.focus\:from-primary-600:focus{
  --tw-gradient-from: #218072;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(33 128 114 / 0));
}
.focus\:to-primary-600:focus{
  --tw-gradient-to: #218072;
}
.focus\:outline-none:focus{
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:ring:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.focus\:ring-primary-300:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(152 217 206 / var(--tw-ring-opacity));
}
.focus-visible\:border-primary-500:focus-visible{
  --tw-border-opacity: 1;
  border-color: rgb(49 158 139 / var(--tw-border-opacity));
}
.focus-visible\:outline-none:focus-visible{
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.group:hover .group-hover\:block{
  display: block;
}
.group:hover .group-hover\:flex{
  display: flex;
}
.group:hover .group-hover\:hidden{
  display: none;
}
.group:hover .group-hover\:translate-x-\[3px\]{
  --tw-translate-x: 3px;
  transform: var(--tw-transform);
}
.group:hover .group-hover\:scale-125{
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: var(--tw-transform);
}
.group:hover .group-hover\:scale-110{
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: var(--tw-transform);
}
.group:hover .group-hover\:scale-105{
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: var(--tw-transform);
}
.group:hover .group-hover\:border-primary-400{
  --tw-border-opacity: 1;
  border-color: rgb(84 192 173 / var(--tw-border-opacity));
}
.group:hover .group-hover\:bg-white{
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.group:hover .group-hover\:text-primary-500{
  --tw-text-opacity: 1;
  color: rgb(49 158 139 / var(--tw-text-opacity));
}
.group:hover .group-hover\:text-secondary-500{
  --tw-text-opacity: 1;
  color: rgb(226 63 108 / var(--tw-text-opacity));
}
.group:hover .group-hover\:text-white{
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.group:hover .group-hover\:text-primary-600{
  --tw-text-opacity: 1;
  color: rgb(33 128 114 / var(--tw-text-opacity));
}
.group:hover .group-hover\:text-gray-900{
  --tw-text-opacity: 1;
  color: rgb(17 39 35 / var(--tw-text-opacity));
}
.group:hover .group-hover\:\!text-gray-900{
  --tw-text-opacity: 1 !important;
  color: rgb(17 39 35 / var(--tw-text-opacity)) !important;
}
.group:hover .group-hover\:text-gray-400{
  --tw-text-opacity: 1;
  color: rgb(156 175 172 / var(--tw-text-opacity));
}
.group:hover .group-hover\:opacity-100{
  opacity: 1;
}
.group:hover .group-hover\:shadow-full-spread{
  --tw-shadow: 0 0 0 1000px;
  --tw-shadow-colored: 0 0 0 1000px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
@media (min-width: 640px){
  .sm\:bottom-5{
    bottom: 1.25rem;
  }
  .sm\:right-28{
    right: 7rem;
  }
  .sm\:left-auto{
    left: auto;
  }
  .sm\:mt-16{
    margin-top: 4rem;
  }
  .sm\:ml-4{
    margin-left: 1rem;
  }
  .sm\:mt-0{
    margin-top: 0px;
  }
  .sm\:block{
    display: block;
  }
  .sm\:h-\[60px\]{
    height: 60px;
  }
  .sm\:h-64{
    height: 16rem;
  }
  .sm\:w-48{
    width: 12rem;
  }
  .sm\:w-32{
    width: 8rem;
  }
  .sm\:w-full{
    width: 100%;
  }
  .sm\:max-w-6xl{
    max-width: 72rem;
  }
  .sm\:grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .sm\:grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .sm\:flex-row{
    flex-direction: row;
  }
  .sm\:items-end{
    align-items: flex-end;
  }
  .sm\:items-center{
    align-items: center;
  }
  .sm\:justify-center{
    justify-content: center;
  }
  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .sm\:space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }
  .sm\:space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:rounded-full{
    border-radius: 9999px;
  }
  .sm\:py-0{
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .sm\:px-4{
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .sm\:py-8{
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .sm\:px-6{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .sm\:pb-2{
    padding-bottom: 0.5rem;
  }
  .sm\:pb-48{
    padding-bottom: 12rem;
  }
  .sm\:text-right{
    text-align: right;
  }
  .sm\:text-3xl{
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  .sm\:text-6xl{
    font-size: 3.75rem;
    line-height: 1;
  }
  .sm\:leading-snug{
    line-height: 1.375;
  }
}
@media (min-width: 768px){
  .md\:absolute{
    position: absolute;
  }
  .md\:top-0{
    top: 0px;
  }
  .md\:left-\[84px\]{
    left: 84px;
  }
  .md\:top-8{
    top: 2rem;
  }
  .md\:left-2\.5{
    left: 0.625rem;
  }
  .md\:top-14{
    top: 3.5rem;
  }
  .md\:left-2{
    left: 0.5rem;
  }
  .md\:mx-auto{
    margin-left: auto;
    margin-right: auto;
  }
  .md\:mt-24{
    margin-top: 6rem;
  }
  .md\:mt-6{
    margin-top: 1.5rem;
  }
  .md\:\!mt-16{
    margin-top: 4rem !important;
  }
  .md\:mb-0{
    margin-bottom: 0px;
  }
  .md\:mt-16{
    margin-top: 4rem;
  }
  .md\:mb-8{
    margin-bottom: 2rem;
  }
  .md\:mt-0{
    margin-top: 0px;
  }
  .md\:ml-\[84px\]{
    margin-left: 84px;
  }
  .md\:ml-0{
    margin-left: 0px;
  }
  .md\:-ml-2{
    margin-left: -0.5rem;
  }
  .md\:ml-32{
    margin-left: 8rem;
  }
  .md\:flex{
    display: flex;
  }
  .md\:hidden{
    display: none;
  }
  .md\:h-screen{
    height: 100vh;
  }
  .md\:h-\[72px\]{
    height: 72px;
  }
  .md\:w-\[calc\(100\%-84px\)\]{
    width: calc(100% - 84px);
  }
  .md\:w-\[84px\]{
    width: 84px;
  }
  .md\:w-auto{
    width: auto;
  }
  .md\:w-10{
    width: 2.5rem;
  }
  .md\:w-64{
    width: 16rem;
  }
  .md\:w-32{
    width: 8rem;
  }
  .md\:max-w-6xl{
    max-width: 72rem;
  }
  .md\:max-w-\[calc\(100\%-84px\)\]{
    max-width: calc(100% - 84px);
  }
  .md\:shrink-0{
    flex-shrink: 0;
  }
  .md\:origin-top-left{
    transform-origin: top left;
  }
  .md\:-rotate-90{
    --tw-rotate: -90deg;
    transform: var(--tw-transform);
  }
  .md\:grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .md\:grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .md\:flex-row{
    flex-direction: row;
  }
  .md\:flex-col{
    flex-direction: column;
  }
  .md\:items-start{
    align-items: flex-start;
  }
  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .md\:space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:\!rounded-md{
    border-radius: 0.375rem !important;
  }
  .md\:\!rounded-l-none{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
  .md\:\!rounded-t-none{
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
  .md\:border-t-0{
    border-top-width: 0px;
  }
  .md\:p-4{
    padding: 1rem;
  }
  .md\:\!px-10{
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .md\:pt-8{
    padding-top: 2rem;
  }
  .md\:pb-4{
    padding-bottom: 1rem;
  }
  .md\:pb-6{
    padding-bottom: 1.5rem;
  }
}
@media (min-width: 1024px){
  .lg\:fixed{
    position: fixed;
  }
  .lg\:col-span-2{
    grid-column: span 2 / span 2;
  }
  .lg\:my-auto{
    margin-top: auto;
    margin-bottom: auto;
  }
  .lg\:mt-0{
    margin-top: 0px;
  }
  .lg\:mt-40{
    margin-top: 10rem;
  }
  .lg\:mt-11{
    margin-top: 2.75rem;
  }
  .lg\:ml-2{
    margin-left: 0.5rem;
  }
  .lg\:ml-24{
    margin-left: 6rem;
  }
  .lg\:ml-96{
    margin-left: 24rem;
  }
  .lg\:mr-16{
    margin-right: 4rem;
  }
  .lg\:ml-4{
    margin-left: 1rem;
  }
  .lg\:block{
    display: block;
  }
  .lg\:flex{
    display: flex;
  }
  .lg\:w-2\/3{
    width: 66.666667%;
  }
  .lg\:w-1\/3{
    width: 33.333333%;
  }
  .lg\:w-3\/5{
    width: 60%;
  }
  .lg\:w-2\/5{
    width: 40%;
  }
  .lg\:w-4\/5{
    width: 80%;
  }
  .lg\:max-w-xl{
    max-width: 36rem;
  }
  .lg\:-skew-x-12{
    --tw-skew-x: -12deg;
    transform: var(--tw-transform);
  }
  .lg\:grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .lg\:grid-cols-5{
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .lg\:grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .lg\:grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .lg\:flex-row{
    flex-direction: row;
  }
  .lg\:space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .lg\:space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:divide-x > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }
  .lg\:pl-8{
    padding-left: 2rem;
  }
}
@media (min-width: 1280px){
  .xl\:mt-0{
    margin-top: 0px;
  }
  .xl\:h-32{
    height: 8rem;
  }
  .xl\:w-1\/2{
    width: 50%;
  }
  .xl\:max-w-5xl{
    max-width: 64rem;
  }
  .xl\:max-w-7xl{
    max-width: 80rem;
  }
  .xl\:max-w-full{
    max-width: 100%;
  }
  .xl\:grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .xl\:flex-row{
    flex-direction: row;
  }
}
@media (min-width: 1536px){
  .\32xl\:mx-auto{
    margin-left: auto;
    margin-right: auto;
  }
  .\32xl\:min-w-\[900px\]{
    min-width: 900px;
  }
  .\32xl\:max-w-5xl{
    max-width: 64rem;
  }
  .\32xl\:max-w-\[600px\]{
    max-width: 600px;
  }
  .\32xl\:grid-cols-6{
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .\32xl\:grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .\32xl\:py-16{
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}
@media (min-width: 1024px){
  @media (min-height: 767px){
    .lg\:v-lg\:absolute{
      position: absolute;
    }
  }
  @media (min-height: 767px){
    .lg\:v-lg\:flex{
      display: flex;
    }
  }
}
@media (min-width: 1792px){
  .\33xl\:max-w-6xl{
    max-width: 72rem;
  }
}

